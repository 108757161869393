<!-- filepath: /Users/khaledtarabein/german-pavilion-registration/german-pavilion-registration/app/src/views/ExhibitorSurvey.vue -->
<template>
  <div v-if="surveyForm.survey_config">
    <v-card class="elevation-1 rounded-0" v-if="surveyForm.survey_config">
      <v-card-title v-if="!participationId" style="margin-bottom: -5px">
        <h4 class="primary--text">
          {{ $t('exhibitorSurvey.title') }}
        </h4>
      </v-card-title>
      <v-card-title v-if="disableForm" style="margin-bottom: -5px">
        <h4 class="primary--text">
          {{ exhibitorName }}
        </h4>
      </v-card-title>
      <v-card-subtitle v-if="!surveyStarted">
        {{ $t('exhibitorSurvey.subtitle') }}
      </v-card-subtitle>
      <v-card-text class="text--primary" v-if="!surveyStarted">
        <v-row>
          <v-col>
            <p v-html="$t('exhibitorSurvey.intro')"></p>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col cols="12" md="4" class="ma-0 py-0">
            <b>{{ $t('exhibitorSurvey.tradeFairName') }}</b>
            <p>
              {{
                surveyForm.participation.relationships.trade_fair.attributes
                  .name_display
              }}
            </p>
          </v-col>
          <v-col cols="12" md="4" class="ma-0 py-0">
            <b>{{ $t('exhibitorSurvey.tradeFairDate') }}</b>
            <p>
              {{
                $moment(
                  surveyForm.participation.relationships.trade_fair.attributes
                    .start_date
                ).format('DD.MM.YYYY')
              }}
              -
              {{
                $moment(
                  surveyForm.participation.relationships.trade_fair.attributes
                    .end_date
                ).format('DD.MM.YYYY')
              }}
            </p>
          </v-col>
          <v-col cols="12" md="4" class="ma-0 py-0">
            <b>{{ $t('exhibitorSurvey.exhibitorName') }}</b>
            <p>
              {{
                surveyForm.participation.relationships.accreditation.attributes
                  .company
              }}
            </p>
          </v-col>
          <v-col v-if="!surveyStarted">
            <v-btn color="primary" @click="startSurvey">
              {{
                disableForm
                  ? $t('exhibitorSurvey.showSurvey')
                  : $t('exhibitorSurvey.startSurvey')
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="elevation-1 rounded-0 my-5 pa-6 success--text"
      v-if="disableForm && surveyStarted && !participationId"
    >
      <h3 v-if="surveyForm.participation.attributes.survey_submitted_at">
        {{
          $t('exhibitorSurvey.successMsg', {
            submissionDate: surveyForm.participation.attributes
              .survey_submitted_at
              ? formatDateByLocale(
                  surveyForm.participation.attributes.survey_submitted_at
                )
              : null,
          })
        }}
      </h3>
      <h3 v-else>
        {{ $t('exhibitorSurvey.successMsgWhenSubmitted') }}
      </h3></v-card
    >

    <v-stepper vertical v-model="activeStep" v-if="surveyStarted" class="pt-6">
      <div v-for="(step, index) in surveyForm.survey_config" :key="index">
        <v-stepper-step
          :step="index + 1"
          :key="index + 1"
          editable
          class="px-5 py-2"
          :complete="activeStep > index + 1"
          :edit-icon="'fa-light fa-pen'"
        >
          <strong style="line-height: 1.5rem" v-if="!$vuetify.breakpoint.xs">{{
            step.attributes.content[$i18n.locale]
          }}</strong>
          <span style="line-height: 1.5rem" v-else>{{
            step.attributes.content[$i18n.locale]
          }}</span>
        </v-stepper-step>

        <v-stepper-content :step="index + 1" class="ma-0">
          <div v-for="children in step.included.children" :key="children.id">
            <v-row v-if="children.attributes.select_type">
              <v-col>
                <checkbox
                  :children="children"
                  v-if="children.attributes.select_type === 'checkbox'"
                  v-model="selectedCheckBoxAnswers"
                  :radioValue="selectedRadioAnswers"
                  :radioAnswer.sync="
                    selectedRadioAnswers[children.attributes.identifier]
                  "
                  :textFieldValue="enteredContents"
                  :textAnswer.sync="
                    enteredContents[children.attributes.identifier]
                  "
                  @startFetching="isInitialLoad = $event"
                  @delete:radioAnswer="deleteRadioItem"
                  @delete:textAnswer="deleteEnteredContent"
                  :disabled="disableForm"
                />
                <radio
                  :children="children"
                  v-else-if="children.attributes.select_type === 'radio'"
                  v-model="selectedRadioAnswers[step.attributes.identifier]"
                  @startFetching="isInitialLoad = $event"
                  :disabled="disableForm"
                />
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col>
                <strong  v-if="hasDependentIdentifier(children)" >{{ children.attributes.content[$i18n.locale] }}</strong>
               
                <div
                  v-for="answer in children.included.children"
                  :key="answer.id"
                  class="d-flex mt-2"
                >
                  <div
                    v-if="answer.attributes.select_type === 'radio'"  
                    class="d-flex"
                  >
                    <radio
                      :children="answer"
                      v-model="
                        selectedRadioAnswers[children.attributes.identifier]
                      "
                      @startFetching="isInitialLoad = $event"
                      :disabled="disableForm"
                      v-if="hasDependentIdentifier(children)"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-row v-if="step.included.children.length == 0" class="mt-1">
            <v-col>
              <text-area
                :textAnswer.sync="enteredContents[step.attributes.identifier]"
                @delete:textAnswer="deleteEnteredContent"
                :value="enteredContents"
                :children="step"
                @startFetching="isInitialLoad = $event"
                :disabled="disableForm"
              ></text-area>
            </v-col>
          </v-row>
          <v-btn color="primary" class="mt-6" @click="nextStep(1)">
            {{ $t('exhibitorSurvey.continueButton') }}
          </v-btn>
        </v-stepper-content>
      </div>
    </v-stepper>

    <v-btn
      color="primary"
      class="mt-4"
      @click="submitSurvey"
      v-if="surveyStarted && !participationId"
      :disabled="disableForm"
      >{{ $t('exhibitorSurvey.submitSurvey') }}</v-btn
    >
  </div>
  <div v-else>
    <div v-if="serverError" class="d-flex justify-start pa-2">
      <h2>
        {{ serverError }}
      </h2>
    </div>
  </div>
</template>

<script>
import Checkbox from './SurveyOptions/Checkbox.vue'
import Vue from 'vue'
import Radio from './SurveyOptions/Radio.vue'
import TextArea from './SurveyOptions/TextArea.vue'
import date from '@/mixins/date'

export default {
  components: { Checkbox, Radio, TextArea },

  props: {
    participationId: {
      type: Number,
    },
    exhibitorName: {
      type: String,
    },
  },

  data() {
    return {
      surveyForm: [],
      selectedCheckBoxAnswers: [],
      selectedRadioAnswers: {},
      enteredContents: {},
      surveyStarted: false,
      disableForm: false,
      serverError: null,
      surveyToken: null,
      isInitialLoad: true,
      activeStep: 1,
    }
  },

  created() {
    this.surveyToken = this.$route.params.token
    this.loadSurveyData()
  },

  mixins: [date],

  computed: {
    combinedSelections() {
      return {
        checkboxes: [...this.selectedCheckBoxAnswers],
        radios: { ...this.selectedRadioAnswers },
        contents: { ...this.enteredContents },
      }
    },
  },

  methods: {
    async loadSurveyData() {
      this.serverError = null
      let params = {}

      if (this.participationId) {
        params.participation_id = this.participationId
      } else if (this.surveyToken) {
        params.survey_token = this.surveyToken
      } else {
        this.serverError = 'Missing required parameters'
        return
      }
      try {
        const response = await this.$axios.get(`/api/survey`, {
          params,
        })

        if (response) {
          this.surveyForm = response.data.data


          if (this.surveyForm.participation.attributes.survey_submitted_at) {
            this.disableForm = true
          }

          this.selectedCheckBoxAnswers = []
          this.selectedRadioAnswers = {}
          this.enteredContents = {}

          this.surveyForm.survey_config.forEach((step) => {
            if (step.attributes.select_type === 'longtext') {
              if (step.relationships.survey_participation.selected) {
                Vue.set(this.enteredContents, step.attributes.identifier, {
                  content: step.relationships.survey_participation.content,
                  id: step.id,
                })
              }
            }
            step.included.children.forEach((child) => {
              if (child.attributes.select_type) {
                if (child.included.children.length) {
                  if (child.attributes.select_type === 'checkbox') {
                    if (child.relationships.survey_participation.selected) {
                      this.selectedCheckBoxAnswers.push(child.id)
                    }
                  }
                  child.included.children.forEach((nestedChild) => {
                    if (
                      nestedChild.relationships.survey_participation.selected
                    ) {
                      if (nestedChild.attributes.select_type === 'radio') {
                        if (!this.selectedRadioAnswers[child.attributes.identifier]) {
                            Vue.set(this.selectedRadioAnswers, child.attributes.identifier, {});
                        }
                   
                        Vue.set(
                            this.selectedRadioAnswers[child.attributes.identifier],
                            nestedChild.attributes.identifier,
                            nestedChild.id
                        );
                      } else if (
                        nestedChild.attributes.select_type === 'shorttext'
                      ) {
                        Vue.set(
                          this.enteredContents,
                          child.attributes.identifier,
                          {
                            content:
                              nestedChild.relationships.survey_participation
                                .content,
                            id: nestedChild.id,
                          }
                        )
                      }
                    }
                  })
                } else {
                  if (child.relationships.survey_participation.selected) {
                    if (child.attributes.select_type === 'checkbox') {
                      this.selectedCheckBoxAnswers.push(child.id)
                    } else if (child.attributes.select_type === 'radio') {
                      if (!this.selectedRadioAnswers[step.attributes.identifier]) {
                        Vue.set(this.selectedRadioAnswers, step.attributes.identifier, {});
                    }
             
                    Vue.set(
                        this.selectedRadioAnswers[step.attributes.identifier],
                        child.attributes.identifier,
                        child.id
                    );
                    }
                  }
                }
              } else {
                child.included.children.forEach((nestedChild) => {
                  if (nestedChild.relationships.survey_participation.selected) {
                    if (nestedChild.attributes.select_type === 'checkbox') {
                      this.selectedCheckBoxAnswers.push(nestedChild.id)
                    } else if (nestedChild.attributes.select_type === 'radio') {
                      if (!this.selectedRadioAnswers[child.attributes.identifier]) {
                        Vue.set(this.selectedRadioAnswers, child.attributes.identifier, {});
                    }
                
                    Vue.set(
                        this.selectedRadioAnswers[child.attributes.identifier],
                        nestedChild.attributes.identifier,
                        nestedChild.id
                    );
                    }
                  }
                })
              }
            })
          })
        }
      } catch (error) {
        if (
          error?.response?.data?.message ===
          'The selected survey token is invalid.'
        ) {
          this.serverError = this.$t('exhibitorSurvey.errors.linkInvalid')
        }
      }
    },

    deleteRadioItem(id) {
      const keyToDelete = Object.keys(this.selectedRadioAnswers).find(
        (key) => this.selectedRadioAnswers[key] === id
      )

      if (keyToDelete) {
        this.$delete(this.selectedRadioAnswers, keyToDelete)
      }
    },
    deleteEnteredContent(id) {
      const keyToDelete = Object.keys(this.enteredContents).find(
        (key) => this.enteredContents[key].id === id
      )

      if (keyToDelete) {
        this.$delete(this.enteredContents, keyToDelete)
      }
    },
    startSurvey() {
      this.surveyStarted = true
      this.activeStep = 1
    },
    nextStep(stepNumber) {
      this.activeStep = this.activeStep + stepNumber
    },
    async sendAnswers() {
      try {
        const radioAnswers = Object.values(this.selectedRadioAnswers)
        .flatMap(parent => Object.values(parent))

        const selectedAnswers = [
        ...this.selectedCheckBoxAnswers,
        ...radioAnswers
        ]

        const enteredContents = Object.values(this.enteredContents).map(
          (item) => ({
            id: item.id,
            content: item.content,
          })
        )

        const payload = {
          survey_submitted: 0,
          selected: selectedAnswers,
          entered: enteredContents,
        }
        await this.$axios.post(`/api/survey/${this.surveyToken}`, payload)
      } catch (error) {
        console.log(error)
      }
    },
    async submitSurvey() {
      try {
        const radioAnswers = Object.values(this.selectedRadioAnswers)
        .flatMap(parent => Object.values(parent));

        const selectedAnswers = [
        ...this.selectedCheckBoxAnswers,
        ...radioAnswers
        ];

        const enteredContents = Object.values(this.enteredContents).map(
          (item) => ({
            id: item.id,
            content: item.content,
          })
        )
        const payload = {
          survey_submitted: 1,
          selected: selectedAnswers,
          entered: enteredContents,
        }
        await this.$axios.post(`/api/survey/${this.surveyToken}`, payload)
        this.disableForm = true
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (error) {
        console.log(error)
      }
    },

    hasDependentIdentifier(question) {
      if (!question.attributes.dependent_identifier) {
        return true
      }

      const parentKeyToRemove = Object.keys(this.selectedRadioAnswers).find(
        (parentKey) =>
          question.attributes.dependent_identifier in this.selectedRadioAnswers[parentKey]
      );

      if (!parentKeyToRemove) {
        delete this.selectedRadioAnswers[question.attributes.identifier]
        return false
      }

      return true
    }

  },

  watch: {
    combinedSelections: {
      async handler() {
        if (!this.isInitialLoad) {
          await this.sendAnswers()
        }
      },
      deep: true,
    },

    participationId: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.surveyStarted = true
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-stepper__step__step {
  padding: 1rem;
  .v-icon.v-icon {
    font-size: 1rem;
  }
}
</style>
