<template>
  <div class="d-flex">
    <v-text-field
      v-model="textValue"
      filled
      class="mt-4"
      hide-details
      :placeholder="$t('exhibitorSurvey.placerHolders.textField')"
      :disabled="disabled"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    disabled: Boolean,
  },

  data() {
    return {
      textValue: this.value,
    }
  },

  watch: {
    textValue: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
}
</script>
