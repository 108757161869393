<template>
  <div>
    <v-app style="background-color: #f9f9f9">
      <public-header />
      <v-main>
        <v-container fluid>
          <Survey></Survey>
        </v-container>
      </v-main>
      <v-footer>
        <logos />
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import Survey from '../components/common/ExhibitorSurvey/Survey.vue'
import Logos from '@/components/base/Logos/Logos'
import PublicHeader from '@/components/public/Header.vue'

export default {
  components: {
    Survey,
    Logos,
    PublicHeader,
  },
  data() {
    return {}
  },
}
</script>
