<template>
  <div class="d-flex align-items-center">
    <label
      style="cursor: pointer; display: flex; align-items: center"
    >
      <input
        type="radio"
        :value="children.id"
        v-model="selectedRadioBox"
        style="width: 25px; height: 25px; margin-bottom: 5px; cursor: pointer"
        :disabled="disabled"
      />
      <div class="ml-2">{{ children.attributes.content[$i18n.locale] }}</div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    children: Object,
    disabled: Boolean,
  },
  computed: {
    selectedRadioBox: {
      get() {
        return this.value?.[this.children?.attributes?.identifier] 
      },
      set(newValue) {
        this.$emit('input', {[this.children.attributes.identifier]:newValue})
        this.$emit('startFetching', false)
      },
    },
  },


}
</script>

<style scoped></style>
