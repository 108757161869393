<template>
  <div>
    <v-container
      v-if="isAvailableToDisplay && currentTradeFair"
      class="pa-9"
      fluid
    >
      <v-card class="elevation-1 rounded-0 mb-5">
        <v-card-title>
          <h4 class="primary--text">
            {{ $t('tradefairsummary.title') }}
            {{ currentTradeFair.nameDisplay }}
          </h4>
        </v-card-title>
        <v-card-subtitle>
          <v-alert dense outlined type="warning" class="my-5">
            {{ $t('tradefairsummary.deadlineAlert') }}
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="currentTradeFair.nameDisplay"
                dense
                :label="$t('tradefairsummary.tradeFairName')"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="date"
                dense
                :label="$t('tradefairsummary.date')"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="location"
                dense
                :label="$t('tradefairsummary.location')"
                disabled
              >
              </v-text-field>
              <v-text-field
                dense
                v-model="form.data.tradefairVenue"
                :label="$t('tradefairsummary.venue')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                :value="
                  currentTradeFair.organizer
                    ? currentTradeFair.organizer.name
                    : null
                "
                :label="$t('tradefairsummary.organizer')"
                disabled
              ></v-text-field>
              <v-select
                v-model="form.data.contactUserId"
                :items="users"
                :item-text="(item) => `${item.firstName} ${item.lastName}`"
                item-value="id"
                dense
                :label="$t('tradefairsummary.contactPerson')"
                :error-messages="errors.contactUserId"
                @change="handleChangeManager"
              >
              </v-select>
              <v-text-field
                v-model="form.data.contactEmail"
                dense
                :label="$t('tradefairsummary.contactEmail')"
                :error-messages="errors.contactEmail"
              ></v-text-field>
              <v-text-field
                v-model="form.data.contactPhone"
                dense
                :label="$t('tradefairsummary.contactPhone')"
                :error-messages="errors.contactPhone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.exhibitors.title') }}:</h3>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="form.data.exhibitorsTotal"
                :error-messages="errors.exhibitorsTotal"
                type="number"
                :label="$t('tradefairsummary.exhibitors.total')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.exhibitorsTotalHa"
                :error-messages="errors.exhibitorsTotalHa"
                type="number"
                :label="$t('tradefairsummary.exhibitors.totalHA')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.exhibitorsHaThereofImporters"
                :error-messages="errors.exhibitorsHaThereofImporters"
                type="number"
                :label="$t('tradefairsummary.exhibitors.thereofImporters')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.exhibitorsTotalUa"
                :error-messages="errors.exhibitorsTotalUa"
                type="number"
                :label="$t('tradefairsummary.exhibitors.totalUA')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.exhibitorsUaThereofImporters"
                :error-messages="errors.exhibitorsUaThereofImporters"
                type="number"
                :label="$t('tradefairsummary.exhibitors.thereofImporters')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.spaces.title') }}:</h3>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                v-model="form.data.spacesTotal"
                :error-messages="errors.spacesTotal"
                type="number"
                :label="$t('tradefairsummary.spaces.total')"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                v-model="form.data.spacesExhibitorSpace"
                :error-messages="errors.spacesExhibitorSpace"
                type="number"
                :label="$t('tradefairsummary.spaces.exhibitorSpace')"
                :hint="$t('tradefairsummary.spaces.exhibitorSpaceHint')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.participations.title') }}:</h3>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.participationsWhichHalls"
                :error-messages="errors.participationsWhichHalls"
                :label="$t('tradefairsummary.participations.whichHalls')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.participationsWhichStates"
                :error-messages="errors.participationsWhichStates"
                :label="$t('tradefairsummary.participations.whichStates')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.participationsFiveBiggest"
                :error-messages="errors.participationsFiveBiggest"
                :label="$t('tradefairsummary.participations.fiveBiggest')"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.participationsGermanCommunity"
                :error-messages="errors.participationsGermanCommunity"
                :label="$t('tradefairsummary.participations.germanCommunity')"
                :hint="
                  $t('tradefairsummary.participations.germanCommunityHint')
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.visitors.title') }}:</h3>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                v-model="form.data.visitorsPreliminary"
                :error-messages="errors.visitorsPreliminary"
                type="number"
                :label="$t('tradefairsummary.visitors.preliminary')"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                v-model="form.data.visitorsForeign"
                :error-messages="errors.visitorsForeign"
                type="number"
                :label="$t('tradefairsummary.visitors.foreign')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.visitorsVip"
                :error-messages="errors.visitorsVip"
                :label="$t('tradefairsummary.visitors.vip')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.supportingEvents.title') }}:</h3>
            </v-col>
            <v-col cols="4">
              <v-select
                class="mt-2"
                label="Typ wählen"
                multiple
                v-model="form.entry"
                :items="supportingEvents"
                item-value="value"
                item-text="text"
              >
              </v-select>
            </v-col>
            <v-col col="7">
              <v-text-field
                dense
                hide-details
                v-model="form.entryText"
                :label="$t('tradefairsummary.enterText')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn icon @click="handleAddEntry">
                <v-icon color="primary" icon>fal fa-add</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-for="(item, i) in form.data.supportingEvents"
            :key="i"
            class="d-flex align-center"
          >
            <v-col cols="4">
              <v-select
                disabled
                class="mt-2"
                :value="form.data.supportingEvents[i].type"
                :items="supportingEvents"
                :label="
                  $t('tradefairsummary.supportingEvents.degustationCounter')
                "
              >
              </v-select>
            </v-col>
            <v-col col="7">
              <v-text-field
                dense
                hide-details
                v-model="form.data.supportingEvents[i].value"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <!-- TODO saves changes on the text field-->
              <!--              <v-btn icon>-->
              <!--                <v-icon color="primary" icon>fal fa-check</v-icon>-->
              <!--              </v-btn>-->
              <!-- TODO removes entry-->
              <v-btn icon @click="handleRemoveEntry(item)">
                <v-icon color="primary" icon>fal fa-remove</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.general.title') }}:</h3>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.generalOpeningBy"
                :error-messages="errors.generalOpeningBy"
                :label="$t('tradefairsummary.general.openingBy')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="form.data.generalReception"
                :error-messages="errors.generalReception"
                :label="$t('tradefairsummary.general.reception')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="form.data.generalArchitect"
                :error-messages="errors.generalArchitect"
                :label="$t('tradefairsummary.general.architect')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.generalCommentsStandConstruction"
                :error-messages="errors.generalCommentsStandConstruction"
                :label="
                  $t('tradefairsummary.general.commentsStandConstruction')
                "
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.generalCommentsCatering"
                :error-messages="errors.generalCommentsCatering"
                :label="$t('tradefairsummary.general.commentsCatering')"
                :hint="$t('tradefairsummary.general.commentsCateringHint')"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.generalCommentsCooperation"
                :error-messages="errors.generalCommentsCooperation"
                :label="$t('tradefairsummary.general.commentsCooperation')"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.generalRecommendation"
                :error-messages="errors.generalRecommendation"
                :label="$t('tradefairsummary.general.recommendation')"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.generalGermanEffect"
                :error-messages="errors.generalGermanEffect"
                :label="$t('tradefairsummary.general.germanEffect')"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.generalCompanyFeedback"
                :error-messages="errors.generalCompanyFeedback"
                :label="$t('tradefairsummary.general.companyFeedback')"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                v-model="form.data.generalSupplementaryRemarks"
                :error-messages="errors.generalSupplementaryRemarks"
                :label="$t('tradefairsummary.general.supplementaryRemarks')"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.images.title') }}:</h3>
            </v-col>
            <v-col cols="5">
              <v-file-input
                counter
                v-model="form.files"
                :show-size="1000"
                :disabled="images.length >= 15"
                :label="$t('tradefairsummary.images.choseFiles')"
                :hint="$t('tradefairsummary.images.hint')"
                accept="image/*"
                multiple
                :error-messages="errors.images"
              ></v-file-input>
            </v-col>
            <v-col cols="2">
              <v-btn :loading="form.loading" @click="handleStoreMedia">
                <v-icon left icon>fal fa-upload</v-icon>
                {{ $t('tradefairsummary.images.upload') }}
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-alert dense outlined type="warning">
                {{ $t('tradefairsummary.images.alert') }}
              </v-alert>
            </v-col>
            <v-container class="fill-height" fluid>
              <v-row>
                <v-col v-for="(item, i) in images" cols="4" :key="i">
                  <v-card elevation="2">
                    <v-img :src="item.blob" max-height="125" contain> </v-img>
                    <v-card-text>
                      <small
                        >{{ item.fileName }},
                        {{ (item.size / 1024).toFixed(2) }} kb,
                        {{ item.width }} x {{ item.height }}</small
                      >
                      <v-col
                        cols="12"
                        class="d-flex align-center py-0 px-0 mt-4"
                      >
                        <v-text-field
                          :label="$t('tradefairsummary.images.charaterization')"
                          v-model="item.name"
                        ></v-text-field>
                        <v-btn
                          :loading="form.loading"
                          icon
                          @click="handleUpdateMedia(item)"
                        >
                          <v-icon color="primary" icon>fal fa-check</v-icon>
                        </v-btn>
                      </v-col>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        :loading="form.loading"
                        class="px-8 ml-3 mb-5"
                        @click="handleDeleteMedia(item)"
                      >
                        {{ $t('tradefairsummary.images.delete') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t('tradefairsummary.checkAndSend.title') }}:</h3>
            </v-col>
            <v-col cols="12" class="d-flex">
              <v-checkbox
                v-model="form.data.privacyPolicy"
                class="pt-1 ma-0 pr-5"
              ></v-checkbox>
              <p>
                {{ $t('tradefairsummary.checkAndSend.privacyPolicy') }}
              </p>
            </v-col>
            <v-col cols="12" class="d-flex">
              <validation-general-error :errors="errors.privacyPolicy" />
            </v-col>
            <v-col cols="12">
              <v-alert dense outlined color="red" type="warning" class="my-5">
                {{ $t('tradefairsummary.checkAndSend.alert') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="4">
              <v-btn
                color="secondary"
                class="px-10 ml-3 mb-5"
                elevation="0"
                :disabled="!isAvailableToSave"
                :loading="form.loading"
                @click="handleSave"
              >
                {{ $t('common.save') }}
              </v-btn>
              <v-btn
                color="secondary"
                class="px-10 ml-3 mb-5"
                elevation="0"
                :disabled="!!generalErrors.length"
                :loading="form.loading"
                @click="handleSend"
              >
                {{ $t('forms.buttons.send') }}
              </v-btn>
            </v-col>
            <v-col cols="7" v-if="generalErrors.length >= 8">
              <small>
                <p class="error--text">
                  {{ $t('tradefairsummary.submitted.errorSummaryForm') }}
                </p>
              </small>
            </v-col>
            <v-col cols="7" v-else>
              <small class="error--text" v-if="generalErrors.length > 0">
                {{ $t('tradefairsummary.submitted.errorSummaryForm') }}:<br />
                <ul v-for="(error, i) in generalErrors" :key="i">
                  <li>
                    {{ error }}
                  </li>
                </ul>
              </small>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
    <v-container v-if="!isAvailableToDisplay" class="pa-9" fluid>
      <v-card class="elevation-1 rounded-0 mb-5">
        <v-card-title>
          <h4 class="primary--text">
            {{ $t('tradefairsummary.submitted.title') }} ({{
              currentTradeFair.nameDisplay
            }})
          </h4>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ $t('tradefairsummary.submitted.description') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="secondary"
                style="text-transform: none"
                :loading="
                  form.loading || !currentTradeFair.summaryGeneratedFile
                "
                @click="handleDownload"
              >
                <v-icon size="16" class="mr-2"> fal fa-file-pdf </v-icon>
                {{ $t('tradefairsummary.submitted.downloadTradefairSummary') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-container class="fill-height" fluid>
              <v-row>
                <v-col v-for="(item, i) in images" cols="4" :key="i">
                  <v-card elevation="2">
                    <v-img :src="item.blob" max-height="125" contain> </v-img>
                    <v-card-text>
                      <small
                        >{{ item.fileName }},
                        {{ (item.size / 1024).toFixed(2) }} kb,
                        {{ item.width }} x {{ item.height }}</small
                      >
                      <v-col
                        cols="12"
                        class="d-flex align-center py-0 px-0 mt-4"
                      >
                        <v-text-field
                          v-model="item.name"
                          :label="$t('tradefairsummary.images.charaterization')"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_TRADE_FAIRS } from '@/store/loading-types'
import validation from '@/mixins/validate'
import { required } from 'vuelidate/lib/validators'
import ValidationGeneralError from '@/components/base/ValidationGeneralError.vue'
import { GENDERS } from '@/enums/genders'

export default {
  name: 'TradefairSummary',

  components: {
    ValidationGeneralError,
  },

  mixins: [validation],

  data() {
    return {
      images: [],
      form: {
        loading: false,
        files: null,
        entry: null,
        entryText: null,
        data: {
          contactUserId: null,
          tradefairVenue: null,
          contactPhone: null,
          contactEmail: null,
          privacyPolicy: null,
          exhibitorsTotal: null,
          exhibitorsTotalHa: null,
          exhibitorsHaThereofImporters: null,
          exhibitorsTotalUa: null,
          exhibitorsUaThereofImporters: null,
          spacesTotal: null,
          spacesExhibitorSpace: null,
          participationsWhichHalls: null,
          participationsWhichStates: null,
          participationsFiveBiggest: null,
          participationsGermanCommunity: null,
          visitorsPreliminary: null,
          visitorsForeign: null,
          visitorsVip: null,
          supportingEvents: [],
          generalOpeningBy: null,
          generalReception: null,
          generalArchitect: null,
          generalCommentsStandConstruction: null,
          generalCommentsCatering: null,
          generalCommentsCateringHint: null,
          generalCommentsCooperation: null,
          generalRecommendation: null,
          generalGermanEffect: null,
          generalCompanyFeedback: null,
          generalSupplementaryRemarks: null,
          image: [
            {
              title: null,
              filename: null,
              imagePath: null,
            },
          ],
        },
        validationRules: {
          contactUserId: {
            required,
          },
          tradefairVenue: {},
          contactPhone: {
            required,
          },
          contactEmail: {
            required,
          },
          privacyPolicy: {
            required,
          },
          exhibitorsTotal: {
            required,
          },
          exhibitorsTotalHa: {
            required,
          },
          exhibitorsHaThereofImporters: {
            required,
          },
          exhibitorsTotalUa: {
            required,
          },
          exhibitorsUaThereofImporters: {
            required,
          },
          spacesTotal: {
            required,
          },
          spacesExhibitorSpace: {
            required,
          },
          participationsWhichHalls: {
            required,
          },
          participationsWhichStates: {
            required,
          },
          participationsFiveBiggest: {
            required,
          },
          participationsGermanCommunity: {
            required,
          },
          visitorsPreliminary: {
            required,
          },
          visitorsForeign: {
            required,
          },
          visitorsVip: {},
          supportingEvents: {},
          generalOpeningBy: {
            required,
          },
          generalReception: {
            required,
          },
          generalArchitect: {},
          generalCommentsStandConstruction: {},
          generalCommentsCatering: {},
          generalCommentsCateringHint: {},
          generalCommentsCooperation: {},
          generalRecommendation: {},
          generalGermanEffect: {},
          generalCompanyFeedback: {},
          generalSupplementaryRemarks: {},
          image: {},
        },
      },
      loaders: {
        getTradeFairs: GET_TRADE_FAIRS,
      },
    }
  },

  async created() {
    this.form.loading = true

    if (this.$route.params.id) {
      this.setCurrentTradeFair(this.$route.params.id)
    }

    await this.fetchUsers()
    await this.fetchCurrentTradeFair()

    this.loadImages()

    for (let key in this.form.data) {
      let summary = this.currentTradeFair.summary ?? []
      if (!summary[key]) {
        continue
      }

      this.form.data[key] = summary[key] ?? null
    }

    this.form.loading = false
  },

  computed: {
    ...mapGetters('organizer', ['tradeFairs', 'currentTradeFair', 'users']),

    supportingEvents() {
      return [
        {
          value: 'Ausstellerabend',
          text: this.$t('tradefairsummary.supportingEvents.exhibitorEvening'),
        },
        {
          value: 'Get Together',
          text: this.$t('tradefairsummary.supportingEvents.getTogether'),
        },
        {
          value: 'Showcooking',
          text: this.$t('tradefairsummary.supportingEvents.showcooking'),
        },
        {
          value: 'Store-Check-Tour',
          text: this.$t('tradefairsummary.supportingEvents.storeCheckTour'),
        },
        {
          value: 'B2B-Matchmaking',
          text: this.$t('tradefairsummary.supportingEvents.matchmaking'),
        },
        {
          value: 'Floristische Show',
          text: this.$t('tradefairsummary.supportingEvents.flowristicShow'),
        },
        {
          value: 'Degustationstheke',
          text: this.$t('tradefairsummary.supportingEvents.degustationCounter'),
        },
        {
          value: 'Infoverstanstaltung',
          text: this.$t('tradefairsummary.supportingEvents.infoEvent'),
        },
        {
          value: 'Sonstiges',
          text: this.$t('tradefairsummary.supportingEvents.other'),
        },
      ]
    },

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },

    date() {
      if (!this.currentTradeFair.id) {
        return ''
      }

      if (this.$i18n.locale === 'de' ? 'DD.MM.YYYY' : 'YYYY-MM-DD') {
        return (
          this.$moment(String(this.currentTradeFair.startDate)).format(
            'DD.MM.YYYY'
          ) +
          ' - ' +
          this.$moment(String(this.currentTradeFair.endDate)).format(
            'DD.MM.YYYY'
          )
        )
      }

      return (
        this.$moment(String(this.currentTradeFair.startDate)).format(
          'YYYY-MM-DD'
        ) +
        ' - ' +
        this.$moment(String(this.currentTradeFair.endDate)).format('YYYY-MM-DD')
      )
    },

    location() {
      return `${this.currentTradeFair.city}, ${this.currentTradeFair.country?.country}`
    },

    isAvailableToDisplay() {
      return !this.currentTradeFair.summarySubmittedAt
    },

    isAvailableToSave() {
      return this.currentTradeFair.summarySubmittedAt === null
    },

    generalErrors() {
      let errors = []

      for (let field in this.form.validationRules) {
        if (
          this.form.validationRules[field]['required'] &&
          !this.form.data[field]
        ) {
          errors.push(
            this.$t('validation.required', {
              field: this.$t(`forms.fields.${field}`),
            })
          )
        }
      }

      const currentDateTime = this.$moment()
      const tradeFairStartDate = this.$moment(this.currentTradeFair.startDate)

      if (!tradeFairStartDate.isSameOrBefore(currentDateTime)) {
        errors.push(this.$t('tradefairsummary.submitted.errorTradeFairDate'))
      }

      if (!(this.currentTradeFair.flagCancelled === false)) {
        errors.push(this.$t('tradefairsummary.submitted.errorFlagCancelled'))
      }

      if (!(this.currentTradeFair.summarySubmittedAt === null)) {
        errors.push(
          this.$t('tradefairsummary.submitted.errorSummarySubmittedAt')
        )
      }

      if (!(this.currentTradeFair.summaryImages?.length >= 4)) {
        errors.push(this.$t('tradefairsummary.submitted.errorSummaryImages'))
      }

      return errors
    },
  },

  methods: {
    ...mapActions('organizer', [
      'fetchUsers',
      'setCurrentTradeFair',
      'fetchCurrentTradeFair',
      'updateTradeFairSummary',
      'sendTradeFairSummary',
      'downloadTradeFairSummary',
      'getTradeFairSummaryMedia',
      'storeTradeFairSummaryMedia',
      'updateTradeFairSummaryMedia',
      'deleteTradeFairSummaryMedia',
    ]),

    async loadImages() {
      let images = []
      for (let media of this.currentTradeFair.summaryImages) {
        images.push({
          ...media,
          id: media.id,
          name: media.name,
          blob: await this.getTradeFairSummaryMedia({
            tradeFairId: this.currentTradeFair.id,
            id: media.id,
          }),
        })

        await new Promise((r) => setTimeout(r, 100))
      }

      this.images = images
    },

    validateImages() {
      let errors = []

      if (!this.form.files) {
        return
      }

      if (this.form.files.length + this.images.length > 15) {
        errors.push(
          this.$t('tradefairsummary.submitted.errorSummaryTooManyImages')
        )
      }

      this.form.files.forEach((file) => {
        if (file.size > 10 * 1024 * 1024) {
          errors.push(
            this.$t('tradefairsummary.submitted.errorSummaryTooSizeImages')
          )
        }
      })

      this.errors.images = errors

      return !errors.length
    },

    async handleChangeManager() {
      let manager = this.users?.find(
        (el) => el.id === this.form.data.contactUserId
      )

      if (manager) {
        this.form.data.contactEmail = manager.email
        this.form.data.contactPhone = manager.phone
      }
    },

    async handleStoreMedia() {
      if (!this.validateImages()) {
        return
      }

      this.form.loading = true
      for (let i in this.form.files) {
        await this.storeTradeFairSummaryMedia({
          tradeFairId: this.currentTradeFair.id,
          file: this.form.files[i],
        })
      }

      this.form.files = null
      this.form.loading = false

      await this.fetchCurrentTradeFair()

      this.loadImages()
    },

    async handleUpdateMedia(item) {
      this.form.loading = true
      await this.updateTradeFairSummaryMedia({
        tradeFairId: this.currentTradeFair.id,
        id: item.id,
        name: item.name,
      })
      this.form.loading = false

      await this.fetchCurrentTradeFair()

      this.loadImages()
    },

    async handleSaveAllMedia() {
      for (let item of this.images) {
        await this.updateTradeFairSummaryMedia({
          tradeFairId: this.currentTradeFair.id,
          id: item.id,
          name: item.name,
        })
      }
    },

    async handleDeleteMedia(item) {
      this.form.loading = true

      await this.deleteTradeFairSummaryMedia({
        tradeFairId: this.currentTradeFair.id,
        id: item.id,
      })

      await this.loadImages()

      this.form.loading = false
    },

    handleAddEntry() {
      for (let entry of this.form.entry) {
        this.form.data.supportingEvents.push({
          type: entry,
          value: this.form.entryText,
        })
      }
    },

    handleRemoveEntry(item) {
      this.form.data.supportingEvents.splice(
        this.form.data.supportingEvents.indexOf(item),
        1
      )
    },

    async handleSave() {
      this.$v.$touch()
      this.form.loading = true

      await this.handleSaveAllMedia()

      if (
        await this.updateTradeFairSummary({
          ...this.form.data,
          tradeFairId: this.currentTradeFair.id,
        })
      ) {
        this.form.loading = false
        return true
      }
    },

    async handleSend() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.form.loading = true

      await this.handleSaveAllMedia()

      await this.updateTradeFairSummary({
        ...this.form.data,
        tradeFairId: this.currentTradeFair.id,
      })

      if (
        await this.sendTradeFairSummary({
          ...this.form.data,
          tradeFairId: this.currentTradeFair.id,
        })
      ) {
        this.form.loading = false

        let interval = setInterval(async () => {
          await this.fetchCurrentTradeFair()

          if (this.currentTradeFair.summaryGeneratedFile) {
            clearInterval(interval)
          }
        }, 3000)
      }
    },

    async handleDownload() {
      if (
        await this.downloadTradeFairSummary({
          tradeFairId: this.currentTradeFair.id,
        })
      ) {
        return true
      }
    },
  },

  watch: {
    'form.files'() {
      this.validateImages()
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>

