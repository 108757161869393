import common from '@/i18n/de/common'
import forms from '@/i18n/de/forms'
import validation from '@/i18n/de/validation'
import header from '@/i18n/de/header'
import auth from '@/i18n/de/auth'
import login from '@/i18n/de/login'
import dashboard from '@/i18n/de/dashboard'
import tables from '@/i18n/de/tables'
import registration from '@/i18n/de/registration'
import onboarding from '@/i18n/de/onboarding'
import settings from '@/i18n/de/settings'
import company from '@/i18n/de/company'
import country from '@/i18n/de/country'
import industrySector from '@/i18n/de/industrySector'
import overview from '@/i18n/de/overview'
import menu from '@/i18n/de/menu'
import notification from '@/i18n/de/notification'
import help from '@/i18n/de/help'
import fairs from '@/i18n/de/fairs'
import tradeFair from '@/i18n/de/tradeFair'
import participation from '@/i18n/de/participation'
import accreditation from '@/i18n/de/accreditation'
import users from '@/i18n/de/users'
import interest from '@/i18n/de/interest'
import publics from '@/i18n/de/public'
import tradefairsummary from '@/i18n/de/tradefairsummary'
import exhibitorSurvey from '@/i18n/de/exhibitorSurvey'

export default {
  ...common,
  ...forms,
  ...validation,
  ...header,
  ...auth,
  ...login,
  ...registration,
  ...onboarding,
  ...settings,
  ...company,
  ...country,
  ...industrySector,
  ...dashboard,
  ...tables,
  ...header,
  ...overview,
  ...menu,
  ...notification,
  ...fairs,
  ...help,
  ...tradeFair,
  ...participation,
  ...accreditation,
  ...users,
  ...interest,
  ...publics,
  ...tradefairsummary,
  ...exhibitorSurvey
}
