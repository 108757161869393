<template>
  <div>
    <v-checkbox
      v-model="selected"
      :value="children.id"
      hide-details
      class="ma-0"
      :label="children.attributes.content[$i18n.locale]"
      @change="handleCheckboxChange"
      :disabled="disabled"
      :ripple="false"
    />

    <template v-if="selected.includes(children.id)">
      <div
        v-for="nestedChild in children.included.children"
        :key="nestedChild.id"
      >
        <radio
          v-if="nestedChild.attributes.select_type === 'radio'"
          :children="nestedChild"
          v-model="selectedRadioAnswers[children.attributes.identifier]"
          class="pl-6 pt-2"
          :disabled="disabled"
        />

        <text-field
          v-if="nestedChild.attributes.select_type === 'shorttext'"
          @input="handleTextChange(nestedChild.id, $event)"
          :value="textFieldValue[children.attributes.identifier]?.content"
          :disabled="disabled"
        ></text-field>
      </div>
    </template>
  </div>
</template>

<script>
import Radio from '../SurveyOptions/Radio.vue'
import TextField from './TextField.vue'
import { debounce } from 'lodash'

export default {
  components: { Radio, TextField },
  props: {
    value: Array,
    radioValue: Object,
    textFieldValue: Object,
    children: Object,
    disabled: Boolean,
  },
  data() {
    return {
      selected: this.value || [],
      selectedRadioAnswers: { ...this.radioValue },
      debouncedHandler: null,
    }
  },

  watch: {
    value(newVal) {
      this.selected = newVal
    },

    textFieldValue: {
      handler(newValue) {
        const identifier = this.children?.attributes?.identifier
        if (
          newValue &&
          identifier &&
          Object.hasOwnProperty.call(newValue, identifier)
        ) {
          if (!this.selected.includes(this.children.id)) {
            this.selected.push(this.children.id)
          }
        }
      },
      immediate: true,
    },

    radioValue: {
      handler(newVal) {
        for (const key in newVal) {
          if (key === this.children.attributes.identifier) {
            if (!this.selected.includes(this.children.id)) {
              this.selected.push(this.children.id)
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    selectedRadioAnswers: {
      handler(newValue) {
        const valueToEmit = newValue[this.children.attributes.identifier]
        this.$emit('update:radioAnswer', valueToEmit)
        this.$emit('startFetching', false)
      },
      deep: true,
    },
  },
  methods: {
    handleCheckboxChange() {
      this.$emit('input', this.selected)
      const firstChild = this.children?.included?.children?.[0]
      const identifier = this.children?.attributes?.identifier
      const textField = this.textFieldValue?.[identifier]

      if (
        firstChild?.attributes?.select_type === 'shorttext' &&
        textField?.id &&
        firstChild?.id === textField?.id
      ) {
        this.$emit('delete:textAnswer', textField.id)
      }

      const tempDeletedItem = this.selectedRadioAnswers?.[identifier]

      if (tempDeletedItem !== undefined) {
        delete this.selectedRadioAnswers[identifier]
        this.$emit('delete:radioAnswer', tempDeletedItem)
      }

      this.$emit('startFetching', false)
    },

    handleTextChange(id, newContent) {
      if (!this.debouncedHandler) {
        this.debouncedHandler = debounce((id, newContent) => {
          this.$emit('update:textAnswer', { id, content: newContent })
          this.$emit('startFetching', false)
        }, 850)
      }

      this.debouncedHandler(id, newContent)
    },
  },
}
</script>
