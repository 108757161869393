<template>
  <div>
    <v-container fluid class="pa-0 my-6">
      <v-data-table
        :headers="formattedHeaders"
        :items="formattedContent"
        item-key="name"
        :hide-default-footer="true"
        disable-pagination
        disable-sort
        class="elevation-3"
        group-by="singleResult"
        ref="table"
        fixed-header
        :height="dynamicHeight"
      >
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td
            :colspan="headers.length"
            v-if="group === $t('exhibitorSurvey.evaluation.individualResult')"
            @click="handleToggle(toggle, isOpen)"
            style="
              cursor: pointer;
              position: sticky;
              top: 48px;
              background-color: #eeeeee;
            "
          >
            <div class="d-flex align-center group-row">
              <strong>{{ group }}</strong>
              <v-spacer></v-spacer>
              <v-btn color="primary" icon class="mb-1 mr-2">
                <v-icon>
                  {{
                    isOpen ? 'fa-light fa-angle-up' : 'fa-light fa-angle-down'
                  }}
                </v-icon>
              </v-btn>
            </div>
          </td>
        </template>

        <template v-slot:item.selected_fair_count="{ item }">
          <a
            @click.prevent="goToDetails(item.fair_id)"
            v-if="item.hasOwnProperty('fair_id')"
            class="text-decoration-underline primary--text"
          >
            {{ item.selected_fair_count }}
          </a>
          <span v-else>
            {{ item.selected_fair_count }}
          </span>
        </template>

        <template v-slot:[`item.${getTextFieldIdentifier()}`]="{ item, value }">
          <div
            v-if="item.hasOwnProperty('fair_id')"
            class="d-flex align-center"
          >
            <span>
              {{
                value > 1
                  ? value + $t('exhibitorSurvey.evaluation.textInputs')
                  : value + $t('exhibitorSurvey.evaluation.textInput')
              }}
            </span>

            <v-icon
              size="18"
              class="ml-2"
              color="primary"
              v-if="value > 0"
              @click="openDialog(item)"
              >fa-solid fa-eye
            </v-icon>
          </div>

          <span v-else>
            {{
              value > 1
                ? value + $t('exhibitorSurvey.evaluation.textInputs')
                : value + $t('exhibitorSurvey.evaluation.textInput')
            }}
          </span>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <div>
              <h3 v-if="surveyItem.parent" class="mt-5">
                {{ surveyItem.parent.included.numbering }}.
                {{ surveyItem.parent.attributes.content[$i18n.locale] }}
              </h3>

              <h4>
                {{ questionNumber }}.
                {{ surveyItem.attributes.content[$i18n.locale] }}
              </h4>
            </div>
          </v-toolbar>
        </template>
      </v-data-table>
      <v-dialog
        v-model="showTextsDialog"
        v-if="selectedFairContent"
        width="500"
        content-class="elevation-0"
        @click:outside="closeDialog"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title class="pa-4">
            <h5 class="primary--text">
              {{ selectedFairContent?.selected_fair_count }}
            </h5>
          </v-card-title>

          <div class="overflow-auto" style="max-height: 500px">
            <div
              v-for="(answer, index) in getFairAnswers(selectedFairContent)"
              :key="index"
            >
              <v-divider></v-divider>
              <div class="px-4 py-2">
                <strong>
                  {{ $t('exhibitorSurvey.evaluation.exhibitor') + ':' }}
                </strong>
                <span>
                  {{ answer.company_name }}
                </span>
                <br />

                <p class="mb-0">{{ answer.content }}</p>
              </div>
            </div>
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex justify-end"
            ><v-btn text color="primary" @click="showTextsDialog = false">{{
              $t('exhibitorSurvey.evaluation.buttonDialogClose')
            }}</v-btn></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    surveyItem: {
      type: Object,
    },
    questionNumber: {
      type: String,
    },
    totals: {
      type: Object,
    },
    tradeFairs: {
      type: Array,
    },
    textAnswers: {
      type: Array,
    },
  },

  data() {
    return {
      showTextsDialog: false,
      selectedFairContent: null,
      isGroupOpen: false,
    }
  },

  computed: {
    formattedHeaders() {
      const headers = [
        {
          text: this.$t('exhibitorSurvey.evaluation.numberSelectedTradeFairs'),
          value: 'selected_fair_count',
          width: '200px',
        },
        {
          text: this.$t('exhibitorSurvey.evaluation.numberQuestionedAnswers'),
          value: 'questioned_answered_count',
          width: '300px',
        },

        ...(this.surveyItem.included.children.length === 0
          ? [
              {
                text: '',
                value: this.surveyItem.attributes.identifier,
                isText: ['longtext', 'shorttext'].includes(
                  this.surveyItem.attributes.select_type
                ),
              },
            ]
          : this.surveyItem.included.children.map((child) => ({
              text: child.attributes.content[this.$i18n.locale],
              value: child.attributes.identifier,
              isText: ['longtext', 'shorttext'].includes(
                child.attributes.select_type
              ),
            }))),
      ]

      return headers
    },

    formattedContent() {
      const content = [
        {
          selected_fair_count: this.totals.tradefairs_count,
          questioned_answered_count: `${this.surveyItem.dependentChild ?this.surveyItem.dependentChild.included.totals.selected_count :  this.surveyItem.included.totals.submitted_count}/${this.surveyItem.included.totals.selected_count} `,
        },
      ]

      const headers = this.formattedHeaders

      for (const header of headers) {
        if (
          header.value !== 'selected_fair_count' &&
          header.value !== 'questioned_answered_count'
        ) {
          // Values of the Headers (Second Row) - Just to know where the start point
          if (
            (this.surveyItem.included.children.length === 0 &&
              this.surveyItem.attributes.select_type === 'longtext') ||
            this.surveyItem.attributes.select_type === 'shorttext'
          ) {
            content[0][
              header.value
            ] = `${this.surveyItem.included.totals.selected_count} `
          } else {
            const child = this.surveyItem.included.children.find(
              (c) => c.attributes.identifier === header.value
            )

            if (child) {
              if (
                child.attributes.select_type === 'longtext' ||
                child.attributes.select_type === 'shorttext'
              ) {
                content[0][
                  header.value
                ] = `${child.included.totals.selected_count} `
              } else {
                content[0][header.value] = `${
                  child.included.totals.selected_count
                } (${child.included.totals.parent_selected_percentage
                  .toFixed(3)
                  .slice(0, -2)} %)`
              }
            }
            // End
          }
        }
      }

      // Values of Grouping Rows - Just to know where the start point
      for (const fair of this.tradeFairs) {
        let singleResults = {
          singleResult: this.$t('exhibitorSurvey.evaluation.individualResult'),
          selected_fair_count: fair.name_display,
          fair_id: fair.id,
          questioned_answered_count: fair.included.surveys_submitted_count,
        }

        if (this.surveyItem.included.children.length === 0) {
          const tradeFairData = this.surveyItem.included.tradefairs.find(
            (t) => t.id === fair.id
          )
          if (
            this.surveyItem.attributes.select_type === 'longtext' ||
            this.surveyItem.attributes.select_type === 'shorttext'
          ) {
            singleResults[
              this.surveyItem.attributes.identifier
            ] = `${tradeFairData.selected_count} `
            singleResults['answers'] = this.surveyItem.included.textanswers
          }
        }

        for (const child of this.surveyItem?.included?.children || []) {
          const tradeFairData = child.included.tradefairs.find(
            (t) => t.id === fair.id
          )
          if (
            child.attributes.select_type === 'shorttext' ||
            child.attributes.select_type === 'longtext'
          ) {
            singleResults[
              child.attributes.identifier
            ] = `${tradeFairData.selected_count} `
            singleResults['answers'] = child.included.textanswers
          } else {
            singleResults[
              child.attributes.identifier
            ] = `${tradeFairData.selected_count}`
          }
        }

        content.push(singleResults)
      }

      return content
    },
    dynamicHeight() {
      return this.isGroupOpen ? '35vh' : undefined
    },
  },

  methods: {
    resetOpenStatus() {
      let table = this.$refs.table
      let keys = Object.keys(table.$vnode.componentInstance.openCache)

      if (keys.length > 1) {
        let secondKey = keys[1]
        table.$vnode.componentInstance.openCache[secondKey] = false
      }
    },

    getTextFieldIdentifier() {
      let header
      header = this.formattedHeaders.find((header) => header.isText)
      return header ? header.value : ''
    },

    getFairAnswers(selectedItem) {
      return selectedItem?.answers.filter(
        (answer) => answer.tradefair_id === selectedItem.fair_id
      )
    },

    openDialog(item) {
      this.selectedFairContent = item
      this.showTextsDialog = true
    },

    closeDialog() {
      this.showTextsDialog = false
      this.selectedFairContent = null
    },

    async goToDetails(id) {
      await this.$router.push({
        name: 'SingleEvaluation',
        query: { id: id },
      })
    },

    handleToggle(toggle, isOpen) {
      toggle()
      this.isGroupOpen = !isOpen
    },
  },

  mounted() {
    this.resetOpenStatus()
    this.showTextsDialog = false
  },
}
</script>

<style lang="scss" scoped>
:deep tr:hover:not(:has(.group-row)) {
  background-color: transparent !important;
}
</style>
