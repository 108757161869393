<template>
  <div>
    <v-dialog
      v-model="display"
      max-width="800"
      @click:outside="closeAddEditModel"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="justify-space-between">
          <h4 class="primary--text">
            {{ $t('participation.contactPerson') }}
          </h4>
          <v-btn color="primary" @click="display = false" icon>
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-container>
          <exhibitor-contact-form
            :key="contactUnique"
            @close="closeAddEditModel"
            :isModal="true"
            :contact="selectedContact"
            :contacts="contacts"
            :language="this.user.language.id"
            @save="handleAddEdit"
            :contactTypeCounts="contactTypeCounts"
          ></exhibitor-contact-form>
        </v-container>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" class="mb-5">
        <v-btn @click="showCreateModal" color="primary" :disabled="isDisabled">
          <v-icon size="16" class="mr-2"> fal fa-plus </v-icon>
          {{ $t('forms.fields.addContact', lang) }}
        </v-btn>
      </v-col>
    </v-row>
    <div
      class="grey lighten-4 pa-4 mb-4"
      v-for="(contact, index) in contacts"
      :key="index"
    >
      <v-row v-if="!isExhibitor">
        <v-col cols="6">
          <v-chip
            v-if="contact.status === 'new'"
            color="success"
            text-color="white"
          >
            {{ $t('forms.fields.toBeAdded', lang) }}
          </v-chip>

          <v-chip
            v-if="contact.status === 'edited'"
            color="orange"
            text-color="white"
          >
            {{ $t('forms.fields.toBeAdjusted', lang) }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row class="d-flex justfiy-center space-between">
        <v-col cols="8 pt-0">
          {{ contact.included.full_name }}<br />
          <small
            >{{ sortContactTypes(contact.attributes.contact_type) }}

            <br />
            <a target="_blank" :href="`mailto:${contact.attributes.email}`">{{
              contact.attributes.email
            }}</a></small
          >
        </v-col>

        <v-col cols="4" class="py-2 d-flex align-center justify-end">
          <v-btn
            @click="showEditModal(contact)"
            icon
            color="secondary"
            :disabled="isDisabled"
          >
            <v-icon size="17">fa-light fa-edit</v-icon>
          </v-btn>
          <v-btn
            @click="showRemoveModal(contact)"
            icon
            color="secondary"
            :disabled="isDisabled"
          >
            <v-icon size="17">fa-light fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-dialog
          v-model="toBeDeleted"
          transition="dialog-bottom-transition"
          max-width="400"
          :retain-focus="false"
          @click:outside="toBeDeleted = false"
        >
          <v-card>
            <v-card-title>
              <h4 class="primary--text">
                {{
                  $t('common.deleteConfirmation', {
                    item:
                      selectedContact?.attributes?.first_name +
                      ' ' +
                      selectedContact?.attributes?.last_name,
                  })
                }}
              </h4>
            </v-card-title>
            <v-card-actions class="d-flex justify-space-between pa-6">
              <v-btn @click="toBeDeleted = false" class="elevation-0">{{
                $t('common.cancel')
              }}</v-btn>
              <v-btn
                @click="handleRemoveContact()"
                class="elevation-0"
                color="secondary"
              >
                {{ $t('common.confirm') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <v-row v-if="hasPendingChanges && !isExhibitor">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="elevation-0"
          @click="applyChanges"
          color="secondary"
          :disabled="isPreviewMode"
        >
          {{ $t('forms.fields.contactType.applyChanges') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="validateContactTyp">
      <v-col class="ml-2 error--text">
        <p v-html="validateContactTyp"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ExhibitorContactForm from '@/components/common/Exhibitor/ContactForm'
import { mapGetters } from 'vuex'

export default {
  name: 'ExhibitorContacts',

  components: {
    ExhibitorContactForm,
  },

  props: {
    participationContacts: [],
    participation: [],
    contact: [],
    fair: {
      type: Object,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('auth', [
      'user',
      'isExhibitor',
      'isOrganizer',
      'isProjectManager',
      'isAdmin',
    ]),

    contactTypeCounts() {
      const typeCounts = {
        participation_preparation: 0,
        on_site: 0,
        media_entry: 0,
      }

      this.contacts.forEach((contact) => {
        if (
          contact.attributes.contact_type.includes('participation_preparation')
        ) {
          typeCounts.participation_preparation++
        }
        if (contact.attributes.contact_type.includes('on_site')) {
          typeCounts.on_site++
        }
        if (contact.attributes.contact_type.includes('media_entry')) {
          typeCounts.media_entry++
        }
      })

      return typeCounts
    },

    isDisabled() {
      let editable = false

      if (
        this.isExhibitor 
        && !this.participation?.attributes?.submitted_at
      ) {
        editable = true
      }
      if (
        (this.isOrganizer || this.isProjectManager || this.isAdmin) 
        && this.participation?.attributes?.submitted_at
      ) {
        editable = true
      }

      return !editable
    },
  },

  data() {
    return {
      sortOrder: ['participation_preparation', 'on_site', 'media_entry'],
      display: false,
      itemToDelete: null,
      selectedContact: null,
      contactUnique: 0,
      contacts: [],
      hasPendingChanges: false,
      toBeDeleted: false,
      validateContactTyp: '',
    }
  },

  created() {
    this.contacts = this.participationContacts.map((contact) => ({
      ...contact,
      status: null,
    }))
  },

  methods: {
    ...mapActions('participation', [
      'deleteContact',
      'createContact',
      'updateContact',
      'duplicateContact',
      'createParticipation',
    ]),

    sortContactTypes(contactTypes) {
      const sortOrder = ['participation_preparation', 'media_entry', 'on_site']
      const typeLabels = {
        participation_preparation: this.$t(
          'forms.fields.contactType.contactPerson'
        ),
        media_entry: this.$t('forms.fields.contactType.dataCollection'),
        on_site: this.$t('forms.fields.contactType.onSite'),
      }

      return contactTypes
        .map((type) => ({
          label: typeLabels[type],
          type: type,
        }))
        .sort((a, b) => sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type))
        .map((item) => item.label)
        .join(', ')
    },

    showCreateModal() {
      this.selectedContact = null
      this.contactUnique += 1
      this.display = true
    },
    showEditModal(contact) {
      this.selectedContact = contact
      this.contactUnique += 1
      this.display = true
    },

    showRemoveModal(contact) {
      this.selectedContact = contact
      this.toBeDeleted = true
    },

    closeAddEditModel() {
      this.display = false
      this.contactUnique += 1
      this.selectedContact = null
    },

    handleAddEdit(newContact) {
      if (newContact.id) {
        const index = this.contacts.findIndex((c) => c.id === newContact.id)
        if (index !== -1) {
          this.contacts[index] = {
            ...this.contacts[index],
            attributes: newContact,
            included: {
              full_name: `${newContact.first_name} ${newContact.last_name}`,
            },
            relationships: {
              language: {
                id: newContact.language_id,
              },
            },
            status: 'edited',
          }
          this.contacts = [...this.contacts]
        }
      } else if (this.selectedContact !== null) {
        const index = this.contacts.findIndex((c) => c === this.selectedContact)
        if (index !== -1) {
          this.contacts[index] = {
            ...this.contacts[index],
            attributes: newContact,
            included: {
              full_name: `${newContact.first_name} ${newContact.last_name}`,
            },
            relationships: {
              language: {
                id: newContact.language_id,
              },
            },
            status: 'new',
          }
          this.contacts = [...this.contacts]
        }
      } else {
        this.contacts.push({
          attributes: newContact,
          included: {
            full_name: `${newContact.first_name} ${newContact.last_name}`,
          },
          relationships: {
            language: {
              id: newContact.language_id,
            },
          },
          status: 'new',
        })
      }

      this.hasPendingChanges = true
      this.closeAddEditModel()
      if (this.isExhibitor) {
        this.applyChanges()
      }
    },

    handleRemoveContact() {
      const indexToRemove = this.contacts.findIndex(
        (item) => item === this.selectedContact
      )

      if (indexToRemove !== -1) {
        this.contacts.splice(indexToRemove, 1)
        this.hasPendingChanges = true
        this.toBeDeleted = false
        this.selectedContact = null
        if (this.isExhibitor) {
          this.applyChanges()
        }
      } else {
        console.error('Not found')
      }
    },

    async applyChanges() {
      this.validateContactTyp = null
      try {
        const formattedContacts = this.contacts.map((contact) => {
          return {
            language_id: contact.relationships.language.id,
            title: contact.attributes.title,
            id: contact.attributes.id,
            gender: contact.attributes.gender,
            phone: contact.attributes.phone,
            mobile: contact.attributes.mobile,
            fax: contact.attributes.fax,
            contact_type: contact.attributes.contact_type,
            first_name: contact.attributes.first_name,
            last_name: contact.attributes.last_name,
            email: contact.attributes.email,
          }
        })

        if (formattedContacts.length === 0) {
          this.validateContactTyp = this.$t('validation.contactRequired')
          return { success: false }
        }

        if (
          this.contactTypeCounts.participation_preparation < 1 &&
          this.contactTypeCounts.media_entry >= 1
        ) {
          this.validateContactTyp = this.$t(
            'validation.contactPersonRequired.contactPersonMin'
          )
          return { success: false }
        }

        if (this.contactTypeCounts.participation_preparation > 2) {
          this.validateContactTyp = this.$t(
            'validation.contactPersonRequired.contactPersonMax'
          )

          return { success: false }
        }

        if (
          this.contactTypeCounts.participation_preparation >= 1 &&
          this.contactTypeCounts.media_entry < 1
        ) {
          this.validateContactTyp = this.$t(
            'validation.contactPersonRequired.mediaMin'
          )
          return { success: false }
        }

        if (this.contactTypeCounts.media_entry > 5) {
          this.validateContactTyp = this.$t(
            'validation.contactPersonRequired.mediaMax'
          )
          return { success: false }
        }

        if (
          this.contactTypeCounts.media_entry < 1 &&
          this.contactTypeCounts.participation_preparation < 1
        ) {
          this.validateContactTyp =
            this.$t('validation.contactPersonRequired.contactPersonMin') +
            '<br>' +
            this.$t('validation.contactPersonRequired.mediaMin')

          return { success: false }
        }

        if (this.contactTypeCounts.on_site > 10) {
          this.validateContactTyp = this.$t(
            'validation.contactPersonRequired.onSite'
          )
          return { success: false }
        }

        if (!this.participation) {
          await this.createParticipation({
            fair: this.fair,
          })
        }

        const response = await this.$axios.post(
          `/api/participations/${this.participation.id}/contacts/all`,
          { contacts: formattedContacts }
        )

        if (this.participation) {
          this.participation.contacts = response.data.data
        }

        this.contacts = response.data.data.map((contact) => ({
          ...contact,
          status: null,
        }))

        this.hasPendingChanges = false
        this.$snackbar(this.$t('common.successApiMessage'))
        this.$emit('refresh')
        return { success: true, data: response.data }
      } catch (error) {
        throw error
      }
    },
  },

  watch: {
    contacts(newValue) {
      if (newValue) {
        this.$emit('countContacts', newValue.length)
      }
    },
  },
}
</script>
