<template>
  <v-card-text>
    <v-row align-content="end">
      <v-col>
        <v-switch
          :label="$t('tradeFair.filters.futureEventsOnly')"
          v-model="filters.future"
          :disabled="loading"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          :label="$t('tradeFair.filters.name')"
          v-model="filters.names"
          :items="fairs"
          :loading="loading"
          :disabled="loading"
          item-text="attributes.name_display"
          item-value="id"
          :no-data-text="$t('common.noDataAvailable')"
          multiple
          clearable
          small-chips
        />
      </v-col>

      <v-col>
        <v-autocomplete
          :label="$t('tradeFair.executionCompany')"
          v-model="filters.organizers"
          :items="organizers"
          :loading="loading"
          :disabled="loading"
          item-text="name"
          item-value="id"
          multiple
          clearable
          small-chips
        />
      </v-col>

      <v-col>
        <v-autocomplete
          :label="$t('tradeFair.filters.year')"
          v-model="filters.years"
          :items="years"
          :loading="loading"
          :disabled="loading"
          multiple
          clearable
          small-chips
        />
      </v-col>

      <v-col>
        <v-autocomplete
          :label="$t('tradeFair.filters.program')"
          v-model="filters.sponsors"
          :items="sponsors"
          :loading="loading"
          :disabled="loading"
          multiple
          clearable
          small-chips
        />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { SPONSORS } from '@/enums/sponsors'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FairSearch',

  data() {
    return {
      loading: false,

      filters: {
        names: [],
        years: [],
        organizers: [],
        sponsors: [],
        future: true,
      },

      fairs: [],
    }
  },

  computed: {
    ...mapGetters('auth', ['user', 'preferences']),

    organizers() {
      let organizers = []

      for (let fair of this.fairs) {
        if (!fair.relationships.organizer) {
          continue
        }

        organizers[fair.relationships.organizer.id] =
          fair.relationships.organizer
      }

      return organizers
    },

    sponsors() {
      return [
        { text: this.$t('forms.sponsors.bmwi'), value: SPONSORS.BMWI },
        { text: this.$t('forms.sponsors.bmel'), value: SPONSORS.BMEL },
      ]
    },

    years() {
      let years = {}

      this.fairs.map(function (item) {
        years[item.attributes.year] = item.attributes.year
      })

      return Object.values(years)
    },
  },

  async created() {
    this.load()

    if (this.preferences.admin_fair_filters) {
      this.filters = this.preferences.admin_fair_filters
    } else {
      this.$emit('input', this.filters)
    }
  },

  methods: {
    ...mapActions('auth', ['fetchUserPreferences', 'setUserPreferences']),

    async load() {
      let page = 1
      let response = null

      do {
        response = (
          await this.$axios.get('/api/trade-fairs', {
            params: {
              languages: '*',
              'page[number]': page,
              'page[size]': 500,
              fields: [
                'name',
                'name_alternative',
                'start_date',
                'organizer_id',
              ],
              include: ['organizer'],
            },
          })
        ).data

        page += 1
        this.fairs = this.fairs.concat(response.data)
      } while (response.data.length)
    },
  },

  watch: {
    filters: {
      handler(value) {
        this.setUserPreferences({
          key: 'admin_fair_filters',
          value: value,
          user_id: this.user.id,
        })

        this.$emit('input', value)
      },
      deep: true,
    },
  },
}
</script>
