<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        clearable
        @click:clear="date = null"
      />
    </template>
    <v-date-picker
      no-title
      color="primary"
      scrollable
      clearable
      @change="handleChange"
      :allowed-dates="allowedDates"
    >
      <v-spacer />
      <v-btn text color="primary" @click="closeMenu">
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="menu = false">
        {{ $t('common.apply') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DateInput',

  props: {
    label: {
      type: String,
      required: false,
    },
    allowedDates: {
      type: Object,
    },
    value: {
      type: String,
    },
  },

  data() {
    return {
      menu: false,
      date: null,
    }
  },

  computed: {
    formattedDate() {
      return this.$root.$options.filters.dateByLocale(this.value)
    },
  },

  methods: {
    handleChange(value) {
      this.date = value
    },
    closeMenu() {
      this.date = null
      this.menu = false
    },
  },

  watch: {
    date: {
      handler(newValue) {
        this.$emit('changed', newValue)
      },
    },
  },
}
</script>
