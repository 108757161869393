<template>
  <v-form :class="isModal ? 'px-3 py-3' : 'mt-2'">
    <v-row>
      <v-col cols="6" class="py-0">
        <v-select
          v-model="form.data.gender"
          :items="genders"
          item-value="type"
          item-text="label"
          :label="$t('forms.fields.salutation')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.gender, 'gender')"
          @blur="$v.form.data.gender.$touch()"
        />
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          v-model="form.data.title"
          :label="$t('forms.fields.title')"
          :hint="$t('forms.fields.titleLabel')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.title, 'title')"
          @blur="$v.form.data.title.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isModal ? 6 : 12" class="py-0">
        <v-text-field
          v-model="form.data.first_name"
          :label="$t('forms.fields.firstName')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.first_name, 'firstName')"
          @blur="$v.form.data.first_name.$touch()"
        />
      </v-col>
      <v-col :cols="isModal ? 6 : 12" class="py-0">
        <v-text-field
          v-model="form.data.last_name"
          :label="$t('forms.fields.lastName')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.last_name, 'lastName')"
          @blur="$v.form.data.last_name.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-text-field
          v-model="form.data.email"
          :label="$t('forms.fields.email')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.email, 'email')"
          @blur="$v.form.data.email.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isModal ? 4 : 12" class="py-0">
        <v-text-field
          v-model="form.data.phone"
          :label="$t('forms.fields.phone')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.phone, 'phone')"
          @blur="$v.form.data.phone.$touch()"
      /></v-col>
      <v-col :cols="isModal ? 4 : 12" class="py-0">
        <v-text-field
          v-model="form.data.mobile"
          :label="$t('forms.fields.mobileIncl')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.mobile, 'mobile')"
          @blur="$v.form.data.mobile.$touch()"
      /></v-col>
      <v-col :cols="isModal ? 4 : 12" class="py-0">
        <v-text-field
          v-model="form.data.fax"
          :label="$t('forms.fields.fax')"
          class="rounded-0"
          filled
          dense
          :error-messages="retrieveError($v.form.data.fax, 'fax')"
          @blur="$v.form.data.fax.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          :label="$t('forms.fields.communicationLanguage')"
          :items="languages"
          v-model="form.data.language_id"
          item-value="id"
          item-text="name"
          filled
          hide-details
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mb-5">
        <v-checkbox
          v-for="item in checkboxItems"
          :key="item.key"
          v-model="form.data.contact_type"
          :value="item.key"
          persistent-hint
          :label="$t(item.label)"
          :hint="$t(item.hint)"
          :disabled="isCheckboxDisabled(item.key)"
        />
      </v-col>
    </v-row>
    <p v-if="errors.contact_type.length" class="error--text">
      {{ $t('validation.contactTypeRequired') }}
    </p>
    <p v-if="addContactErrorMsg" class="error--text">
      {{ addContactErrorMsg }}
    </p>
    <v-row :class="isModal ? 'justify-space-between px-3 py-3' : 'ml-0 pt-2'">
      <v-btn v-if="isModal" @click="$emit('close')" class="elevation-0 col-2">
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn @click="handleSubmit" class="elevation-0 secondary">
        {{
          contact ? $t('forms.fields.adjust') : $t('forms.fields.addContact')
        }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { GENDERS } from '@/enums/genders'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import { mapGetters } from 'vuex'

export default {
  name: 'ContactForm',
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => null,
    },
    language: {
      type: Number,
    },
    contacts: {
      type: Array,
    },
    contactTypeCounts: {
      type: Object,
    },
  },

  mixins: [validation],

  data() {
    return {
      form: {
        data: {
          title: '',
          first_name: '',
          last_name: '',
          gender: null,
          phone: '',
          mobile: '',
          fax: '',
          email: '',
          contact_type: [],
          language_id: '',
          id: '',
        },

        validationRules: {
          title: {
            maxLength: maxLength(255),
          },
          first_name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
          },
          last_name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
          },
          gender: {
            required,
          },
          phone: {
            maxLength: maxLength(255),
          },
          mobile: {
            maxLength: maxLength(255),
          },
          fax: {
            maxLength: maxLength(255),
          },
          email: {
            required,
            email,
            maxLength: maxLength(255),
          },
          contact_type: {
            required,
          },
          language_id: {
            required,
          },
          id: {},
        },
      },
      checkboxItems: [
        {
          key: 'participation_preparation',
          label: 'forms.fields.contactType.contactPerson',
          hint: 'forms.fields.contactType.contactPersonHint',
        },
        {
          key: 'media_entry',
          label: 'forms.fields.contactType.dataCollection',
          hint: 'forms.fields.contactType.dataCollectionHint',
        },
        {
          key: 'on_site',
          label: 'forms.fields.contactType.onSite',
          hint: 'forms.fields.contactType.onSiteHint',
        },
      ],
      addContactErrorMsg: null,
    }
  },

  computed: {
    ...mapGetters('languages', ['languages','language']),
    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },
  },

  watch: {
    contact: {
      immediate: true,
      handler(newContact) {
        if (newContact) {
          Object.assign(this.form.data, newContact.attributes)
          this.form.data.language_id = newContact.relationships.language.id
        } else {
          this.form.data.language_id = this.language.id
        }
      },
    },
  },

  methods: {
    isCheckboxDisabled(contactTypeId) {
      if (!this.contact) {
        const count = this.contactTypeCounts[contactTypeId]
        if (contactTypeId === 'participation_preparation') {
          return count >= 2
        } else if (contactTypeId === 'media_entry') {
          return count >= 5
        } else if (contactTypeId === 'on_site') {
          return count >= 10
        }
        return false
      }
    },

    handleSubmit() {
      this.addContactErrorMsg = null

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.$emit('save', this.form.data)
    },

    retrieveError(field, fieldName) {
      if (field.$error) {
        if (!field.required && field.$params.required) {
          return this.$t('validation.required', {
            field: this.$t(`forms.fields.${fieldName}`),
          })
        } else if (!field.maxLength && field.$params.maxLength) {
          return this.$t('validation.maxLength', {
            field: this.$t(`forms.fields.${fieldName}`),
            length: field.$params.maxLength.max,
          })
        } else if (!field.minLength && field.$params.minLength) {
          return this.$t('validation.minLength', {
            field: this.$t(`forms.fields.${fieldName}`),
            length: field.$params.minLength.min,
          })
        } else if (!field.email && field.$params.email) {
          return this.$t('validation.email', {
            field: this.$t(`forms.fields.${fieldName}`),
          })
        }
      }
      return ''
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
