export default {
  forms: {
    fields: {
      signature: 'Unterschrift',
      title: 'Titel',
      titleLabel: 'Akadem. Titel (z.B. Dr, Prof.)',
      amount: 'Menge',
      firstName: 'Vorname',
      lastName: 'Nachname',
      name: 'Name',
      phone: 'Telefon',
      mobileIncl: 'Mobiltelefon mit Landesvorwahl',
      mobile: 'Mobil',
      email: 'E-Mail',
      password: 'Passwort',
      passwordConfirmation: 'Passwort wiederholen',
      userEmail: 'Benutzer E-Mail',
      organizerEmail: 'Veranstalter E-Mail',
      registrationType: 'Registration type',
      state: 'Bundesland',
      vatID: 'USt-ID',
      vatId: 'USt-ID',
      fax: 'Fax',
      website: 'Internet',
      company: 'Firma',
      street: 'Straße',
      address: 'Anschrift',
      addresssupplement: 'Adresszusatz',
      postalcode: 'PLZ',
      postbox: 'Postfach',
      postboxPostalcode: 'Postfach-PLZ',
      city: 'Ort',
      country: 'Land',
      responsible: 'Zuständig',
      tel: 'Tel',
      federalState: 'Bundesland',
      federalStateRequired: 'Bitte wählen Sie ein Bundesland aus',
      gender: 'Geschlecht',
      salutation: 'Anrede',
      contactTitle: 'Titel',
      contactGender: 'Anrede',
      contactFirstname: 'Vorname',
      contactLastname: 'Nachname',
      contactName: 'Name',
      contactEmail: 'E-Mail',
      contactPhone: 'Telefon',
      contactFax: 'Fax',
      communicationLanguage: 'Korrespondenzsprache',
      male: 'Männlich',
      female: 'Weiblich',
      weight: 'Gewicht',
      height: 'Höhe',
      width: 'Breite',
      depth: 'Tiefe',
      weightHint: 'Gewicht in kg',
      heightHint: 'Höhe in Zentimetern (cm)',
      widthHint: 'Breite in Zentimetern (cm)',
      depthHint: 'Tiefe in Zentimetern (cm)',
      hints: 'Hinweise',
      incorrectInformationAwareness: 'Falsches Informationsbewusstsein',
      size: 'Größe',
      withStandConstruction: 'Mit Standbau',
      registryCourt: 'Registergericht, Gewerbeamt oder ähnliche Institution',
      registryNumberEntry: 'Registernummer oder "nicht vorhanden" eintragen',
      registryNumber: 'Registernummer',
      taxNumber: 'Steuernummer',
      vatIdNo: 'USt-ID',
      registerExcerpt:
        'Handelsregisterauszug, Gewerbeanmeldung oder ähnliches Dokument',
      document:
        'Handelsregisterauszug, Gewerbeanmeldung oder ähnliches Dokument',
      recognizeConditionsForParticipation:
        'Bedingungen für die Teilnahme an Messen im Ausland',
      dataProtectionDeclaration:
        'Datenschutzerklärung für die Teilnahme an Messen im Ausland',
      area: 'Ausstellungsbereich',
      differentAddressForSendingInvoicesSet: 'Abweichende Rechnungsanschrift',
      differentAddressForSendingInvoicesNotSet:
        'Ich möchte eine abweichende Rechnungsanschrift eintragen.',
      organization: 'Organisation',
      msMr: 'Frau/Herr',
      userType: 'Nutzertyp',
      association: 'Verband',
      created: 'Erstellt',
      adjust: 'anpassen',
      create: 'Erstellen',
      toBeAdded: 'Kontakt wird hinzugefügt',
      toBeAdjusted: 'Wird aktualisiert',
      toBeDeleted: 'Wird gelöscht',
      invite: 'Einladen',
      lastLogin: 'Letzter Login',
      lastUpdated: 'Letzte Aktualisierung',
      type: 'Typ',
      role: 'Rolle',
      termsOfUse: 'Nutzungsbedingungen',
      termsOfUseAdoption: {
        before: 'Ich habe die',
        link: 'Datenschutzerklärung',
        after: 'gelesen und erkläre mich damit einverstanden.',
      },
      sendTo: 'Senden an',
      groups: 'Senden an',
      subject: 'Betreff',
      message: 'Nachricht',
      body: 'Nachricht',
      participatedMoreThanFourTimes: 'Offizieller Beteiligungsbetrag',
      manager: 'Projektleiter',
      ministryApproval: 'Freigabe BMWK/BAFA/BMEL',
      activeUser: 'Aktiver Nutzer',
      minParticipants: 'Minimum number of participants',
      spaceMinSize: 'Mindestausstellungsfläche',
      spaceMaxSize: 'Maximale Ausstellungsfläche',
      sizeFrom: 'Größe von',
      sizeTo: 'Größe bis',
      priceSponsored: 'Preis 1. - 4. Teilnahme',
      priceUnsponsored: 'Preis ab 5. Teilnahme',
      pricePublicsector: 'Vollkosten',
      services: 'Services included',
      coexhibitorFee: 'Veranstalter-Gebühr pro Unteraussteller',
      coexhibitorFeeTitle: 'Bezeichnung der Gebühr',
      coexhibitorFeeDescription: 'Hinweis / Beschreibung',
      coexhibitorFeeTitle_de: 'Bezeichnung der Gebühr (deutsch)',
      coexhibitorFeeDescription_de: 'Hinweis / Beschreibung (deutsch)',
      coexhibitorFeeTitle_en: 'Bezeichnung der Gebühr (englisch)',
      coexhibitorFeeDescription_en: 'Hinweis / Beschreibung (englisch)',
      coexhibitorExternalFee: 'Veranstalter-Gebühr pro Unteraussteller',
      coexhibitorExternalFeeTitle: 'Bezeichnung der Gebühr',
      coexhibitorExternalFeeTitle_de: 'Bezeichnung der Gebühr (deutsch)',
      coexhibitorExternalFeeTitle_en: 'Bezeichnung der Gebühr (englisch)',
      coexhibitorExternalFee2: 'Veranstalter-Gebühr pro Unteraussteller 2',
      coexhibitorExternalFee2Title: 'Bezeichnung der Gebühr 2',
      coexhibitorExternalFee2Title_de: 'Bezeichnung der Gebühr (deutsch) 2',
      coexhibitorExternalFee2Title_en: 'Bezeichnung der Gebühr (englisch) 2',
      description: 'Beschreibung',
      price: 'Preis',
      unit: 'Einheit',
      unit_de: 'Einheit (deutsch)',
      unit_en: 'Einheit (englisch)',
      specificationLabel: 'Spezifikationsetikett',
      bookingTypeSpace: 'Ausstellungsfläche',
      bookingTypePackage: 'Paket/Pauschale',
      furtherParticipationRequirements:
        'Teilnahmevoraussetzung: Weitere Angaben zu Ihrem Unternehmen',
      germanRelations:
        'In welcher Beziehung steht Ihr Unternehmen zu einem deutschen Unternehmen?',
      foreignBranch: 'Ausländische Niederlassung eines deutschen Unternehmens',
      foreignRepresentation:
        'Ausländische Vertretung eines deutschen Unternehmens',
      relatedCompany:
        'Geben Sie bitte den Namen des deutschen Unternehmens hier ein',
      noRelations: 'Kein Bezug zu einem deutschen Unternehmen',
      noRelationsMessage:
        'Sie können leider den Akkreditierungsprozess zur Teilnahme am Auslandsmesseprogramm nicht fortsetzen. Teilnahmeberechtigt sind nur Unternehmen aus Deutschland sowie deren ausländische Niederlassungen und Vertretungen.',
      contactTradeFairParticipation: 'Ansprechpartner für Messeteilnahmen',
      headline_de: 'Überschrift deutsch',
      headline_en: 'Überschrift englisch',
      abstract_de: 'Zusammenfassung deutsch',
      abstract_en: 'Zusammenfassung englisch',
      text_de: 'Text deutsch',
      text_en: 'Text englisch',
      contactUserId: 'Ansprechpartner',
      exhibitor: 'Aussteller',
      exhibitorsTotal: 'Aussteller insgesamt',
      exhibitorsTotalHa: 'Hauptaussteller German Pavilion insgesamt',
      exhibitorsHaThereofImporters: 'davon Importeure',
      exhibitorsTotalUa: 'Unteraussteller German Pavilion insgesamt',
      exhibitorsUaThereofImporters: 'davon Importeure',
      spacesTotal: 'Gesamtfläche in ㎡',
      spacesExhibitorSpace: 'Fläche d. Aussteller in ㎡',
      spacesExhibitorSpaceHint: 'ggf. mit Freifläche',
      participationsWhichHalls: 'Beteiligung in welchen Hallen (Sektor und ㎡)',
      participationsWhichStates: 'Beteiligung von Bundesländern',
      participationsFiveBiggest: 'Fünf größte Beteiligungen anderer Staaten',
      participationsGermanCommunity: 'Beteiligung deutscher Unternehmen',
      participationsGermanCommunityHint:
        'außerhalb d. deutschen Gemeinschaftstandes',
      visitorsPreliminary: 'Vorläufige Besucherzahl',
      visitorsForeign: 'davon ausländisch',
      visitorsVip: 'VIP-Besuche am German Pavilion',
      generalOpeningBy: 'Eröffnung durch',
      generalReception: 'BMEL-/BMWK-Empfang',
      privacyPolicy: 'Datenschutz',
      billPerEMail: 'Rechnung per E-Mail',
      billingEMail: 'Rechnungs-E-Mail-Adresse',
      billingaddressEmail: 'Rechnungs-E-Mail-Adresse',
      brand: 'Markennamen',
      brandFlag: 'Markennamen einfügen',
      brandPlaceholder:
        'Tragen Sie den Markennamen ein, den Sie hier mit Ihrem Unternehmensnamen repräsentieren wollen',
      companyId: 'Unternehmen',
      invitedCompanyId:
        'Wählen Sie ein Unternehmen aus, zu dem der Nutzer eingeladen werden soll',
      invitedCompanyIdHint: 'Einladung an Unternehmen/Aussteller',
      english: 'Englisch',
      german: 'Deutsch',
      addContact: 'Ansprechpartner anlegen',
      adjustContact: 'Ansprechpartner anpassen',
      contactType: {
        responsibilities: 'Verantwortlichkeiten',
        contactPerson: 'Organisation und Messevorbereitung',
        contactPersonHint: 'Kontaktperson für diese Messe.',
        onSite: 'Auf der Messe',
        onSiteHint: 'Person, die auf der Messe am Stand präsent ist.',
        dataCollection: 'Medieneintrag',
        dataCollectionHint:
          'Person für die Datenerfassung vom Medieneintrag (Online, Print, Social Media)',
        applyChanges: 'Änderungen übernehmen',
      },
    },
    buttons: {
      save: 'Speichern',
      continue: 'Weiter',
      saveAndContinue: 'Speichern und weiter',
      add: 'Hinzufügen',
      addEntry: 'Eintrag hinzufügen',
      submit: 'Senden',
      upload: 'Hochladen',
      completeAndSend: 'Abschließen und senden',
      send: 'Senden',
      search: 'Suchen',
      preview: 'Vorschau',
    },
    hints: {
      notAvailable: 'Nicht verfügbar',
      password:
        'Das Passwort muss aus mindestens acht Zeichen bestehen und mindestens einen Großbuchstaben und eine Zahl enthalten',
    },
    sponsors: {
      bmwi: 'BMWK',
      bmel: 'BMEL',
    },
  },
}
