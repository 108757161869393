import Vue from 'vue'
import VueRouter from 'vue-router'

import ExhibitorHome from '@/views/exhibitor/Home'
import ExhibitorFairs from '@/views/exhibitor/Fairs'
import Notification from '@/views/Notification'
import Notifications from '@/views/Notifications'
import AppExhibitor from '@/views/exhibitor/App'
import AppExhibitorLogin from '@/views/exhibitor/Auth/Login'
import AppExhibitorSignup from '@/views/exhibitor/Auth/Signup'
import AppExhibitorVerify from '@/views/exhibitor/Auth/Verify'
import ExhibitorHelp from '@/views/exhibitor/Help'
import ExhibitorOnboard from '@/views/exhibitor/Onboard'
import ExhibitorUsers from '@/views/exhibitor/Users'
import ExhibitorSettings from '@/views/exhibitor/Settings'
import ExhibitorParticipation from '@/views/exhibitor/Participation/Participation'
import ExhibitorParticipationDetail from '@/views/exhibitor/Participation/Detail'
import ExhibitorParticipationOverview from '@/views/exhibitor/Participation/ParticipationOverview'
import ExhibitorParticipationApplication from '@/views/exhibitor/Participation/Application'
import ExhibitorAccreditation from '@/views/exhibitor/ExhibitorAccreditation'
import ExhibitorFairDetail from '@/views/exhibitor/FairDetail'

import ExhibitorSurvey from '@/views/ExhibitorSurvey'
import Evaluation from '../views/Evaluation.vue'
import SingleEvaluation from '../views/SingleEvaluation.vue'
import MultipleEvaluation from '../views/MultipleEvaluation.vue'

import AppOrganizer from '@/views/organizer/App'
import OrganizerDashboard from '@/views/organizer/Dashboard'
import OrganizerApplication from '@/views/organizer/OrganizerApplication'
import OrganizerInterest from '@/views/organizer/Interest'
import OrganizerParticipants from '@/views/organizer/Participants'
import OrganizerTradefairSummary from '@/views/organizer/TradefairSummary'
import OrganizerParticipantsOverview from '@/views/organizer/ParticipantsOverview'
import OrganizerParticipantsDetail from '@/views/organizer/ParticipantsDetail'
import OrganizerSettings from '@/views/organizer/Settings'
import OrganizerUsers from '@/views/organizer/Users'
import OrganizerSendNotification from '@/views/organizer/SendNotification'
import OrganizerNotes from '@/views/organizer/Notes'

import AdminApp from '@/views/admin/App'
import AdminDashboard from '@/views/admin/Dashboard'
import AdminAccreditations from '@/views/admin/Accreditations'
import AdminCompanies from '@/views/admin/Companies'
import AdminFairs from '@/views/admin/Fairs'
import AdminCompany from '@/views/admin/Company'
import AdminUsers from '@/views/admin/Users'
import AdminFAQ from '@/views/admin/FAQ'
import AdminHelp from '@/views/admin/Help'
import AdminNotes from '@/views/admin/Notes'
import AdminSendNotification from '@/views/admin/SendNotification'

import SupervisorApp from '@/views/supervisor/App'
import SupervisorDashboard from '@/views/supervisor/Dashboard'
import SupervisorFairs from '@/views/supervisor/Fairs'
import SupervisorFairView from '@/views/supervisor/FairView'
import SupervisorNotes from '@/views/supervisor/Notes'
import SupervisorCompanies from '@/views/supervisor/Companies'
import SupervisorCompany from '@/views/supervisor/Company'

import AppLogin from '@/views/Login'
import Imprint from '@/views/Imprint'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import NotFound from '@/views/NotFound'
import ServerError from '@/views/ServerError'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AppExhibitorLogin,
    name: 'exhibitor.home',
    meta: { requiresAuth: false },
    children: [
      {
        path: '/reset-password/:token/:email',
        component: AppExhibitorLogin,
        name: 'exhibitor.reset',
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/login/',
    component: AppExhibitorLogin,
    name: 'exhibitor.login',
    meta: { requiresAuth: false },
  },
  {
    path: '/signup/',
    component: AppExhibitorSignup,
    name: 'exhibitor.signup',
    meta: { requiresAuth: false },
  },
  {
    path: '/verify/',
    component: AppExhibitorVerify,
    name: 'exhibitor.verify',
    meta: { requiresAuth: false, ignoreSize: true },
  },
  {
    path: '/exhibitor/',
    component: AppExhibitor,
    children: [
      {
        path: '/exhibitor/dashboard',
        component: ExhibitorHome,
        name: 'exhibitor.dashboard',
        meta: {
          requiresAuth: true,
          title: 'Exhibitor dashboard',
          description: 'Exhibitor dashboard',
        },
      },
      {
        path: '/exhibitor/onboard',
        component: ExhibitorOnboard,
        name: 'exhibitor.onboard',
        meta: {
          requiresAuth: true,
          title: 'Exhibitor onboard',
          description: 'Exhibitor onboard',
        },
      },
      {
        path: '/exhibitor/fairs',
        component: ExhibitorFairs,
        name: 'exhibitor.fairs',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/accreditation',
        component: ExhibitorAccreditation,
        name: 'exhibitor.accreditation',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/help',
        component: ExhibitorHelp,
        name: 'exhibitor.helps',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/help/:id',
        component: ExhibitorHelp,
        name: 'exhibitor.help',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/users',
        component: ExhibitorUsers,
        name: 'exhibitor.users',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/settings',
        component: ExhibitorSettings,
        name: 'exhibitor.settings',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/participation',
        component: ExhibitorParticipation,
        children: [
          {
            path: 'overview',
            component: ExhibitorParticipationOverview,
            name: 'exhibitor.participation.overview',
            meta: { requiresAuth: true },
          },
          {
            path: ':id',
            component: ExhibitorParticipationDetail,
            name: 'exhibitor.participation.view',
            meta: { requiresAuth: true },
          },
          {
            path: 'fair/:id',
            component: ExhibitorFairDetail,
            name: 'exhibitor.fair.view',
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/exhibitor/application/:id',
        component: ExhibitorParticipationApplication,
        name: 'exhibitor.participations.application',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/notifications/:id',
        component: Notification,
        name: 'exhibitor.notification',
        meta: { requiresAuth: true },
      },
      {
        path: '/exhibitor/notifications',
        component: Notifications,
        name: 'exhibitor.notifications',
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: '/signin/',
    component: AppLogin,
    name: 'login',
  },

  {
    path: '/organizer/',
    component: AppOrganizer,
    children: [
      {
        path: '/organizer/dashboard',
        component: OrganizerDashboard,
        name: 'organizer.dashboard',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/application',
        component: OrganizerApplication,
        name: 'organizer.application',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/interest',
        component: OrganizerInterest,
        name: 'organizer.interest',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/participants',
        component: OrganizerParticipants,
        children: [
          {
            path: '/organizer/participants/overview',
            component: OrganizerParticipantsOverview,
            name: 'organizer.participants',
            meta: { requiresAuth: true },
          },
          {
            path: '/organizer/participants/:id',
            component: OrganizerParticipantsDetail,
            name: 'organizer.participants.detail',
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/organizer/tradefairsummary/:id?',
        component: OrganizerTradefairSummary,
        name: 'organizer.tradefairsummary',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/users',
        component: OrganizerUsers,
        name: 'organizer.users',
        meta: { requiresAuth: true, onlyOrganizer: true },
      },
      {
        path: '/organizer/settings',
        component: OrganizerSettings,
        name: 'organizer.settings',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/notifications',
        component: Notifications,
        name: 'organizer.notifications',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/notifications/:id',
        component: Notification,
        name: 'organizer.notification',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/notifications-send',
        component: OrganizerSendNotification,
        name: 'organizer.notifications.send',
        meta: { requiresAuth: true },
      },
      {
        path: '/organizer/notes/:id',
        component: OrganizerNotes,
        name: 'organizer.notes',
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: '/supervisor/',
    component: SupervisorApp,
    children: [
      {
        path: '/supervisor/dashboard',
        component: SupervisorDashboard,
        name: 'supervisor.dashboard',
        meta: { requiresAuth: true },
      },
      {
        path: '/supervisor/fairs',
        component: SupervisorFairs,
        name: 'supervisor.fairs',
        meta: { requiresAuth: true },
      },
      {
        path: '/supervisor/fairs/:id',
        component: SupervisorFairView,
        name: 'supervisor.fair',
        meta: { requiresAuth: true },
      },
      {
        path: '/supervisor/notes/:id',
        component: SupervisorNotes,
        name: 'supervisor.notes',
        meta: { requiresAuth: true },
      },
      {
        path: '/supervisor/companies',
        component: SupervisorCompanies,
        name: 'supervisor.companies',
        meta: { requiresAuth: true },
      },
      {
        path: '/supervisor/companies/:id',
        component: SupervisorCompany,
        name: 'supervisor.company',
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: '/admin/',
    component: AdminApp,
    children: [
      {
        path: '/admin/dashboard',
        component: AdminDashboard,
        name: 'admin.dashboard',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/companies',
        component: AdminCompanies,
        name: 'admin.companies',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/companies/:id',
        component: AdminCompany,
        name: 'admin.company',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/accreditations',
        component: AdminAccreditations,
        name: 'admin.accreditations',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/fairs',
        component: AdminFairs,
        name: 'admin.fairs',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/fairs/:id',
        component: SupervisorFairView,
        name: 'admin.fair',
      },
      {
        path: '/admin/help',
        component: AdminHelp,
        name: 'admin.helps',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/faq',
        component: AdminFAQ,
        name: 'admin.faqs',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/faq/:id',
        component: AdminFAQ,
        name: 'admin.faq',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/users',
        component: AdminUsers,
        name: 'admin.users',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/notifications/:id',
        component: Notification,
        name: 'admin.notification',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/notifications',
        component: Notifications,
        name: 'admin.notifications',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/notifications-send',
        component: AdminSendNotification,
        name: 'admin.notifications.send',
        meta: { requiresAuth: true },
      },
      {
        path: '/admin/notes/:id',
        component: AdminNotes,
        name: 'admin.notes',
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: '/ExhibitorSurvey/:token',
    component: ExhibitorSurvey,
    name: 'exhibitor.survey',
    meta: {
      requiresAuth: false,
      title: 'Exhibitor survey',
      description: 'Exhibitor survey',
      ignoreSize: true,
    },
  },

  {
    path: '/:role/evaluation',
    name: 'Evaluation',
    component: Evaluation,
    props: true,
    children: [
      {
        path: 'single-evaluation/',
        name: 'SingleEvaluation',
        component: SingleEvaluation,
        props: (route) => ({ id: route.query.id }),
      },
      {
        path: 'multiple-evaluation/',
        name: 'MultipleEvaluation',
        component: MultipleEvaluation,
      },
    ],
  },

  {
    path: '/server-error',
    component: ServerError,
    name: 'serverError',
    meta: { ignoreSize: true },
  },

  {
    path: '/imprint/',
    component: Imprint,
    name: 'documents.imprint',
    meta: { ignoreSize: true },
  },

  {
    path: '/privacy-policy/',
    component: PrivacyPolicy,
    name: 'documents.privacyPolicy',
    meta: { ignoreSize: true },
  },

  {
    path: '*',
    component: NotFound,
    name: 'pageNotFound',
    meta: { ignoreSize: true },
  },
]

export default routes
