<template>
  <v-navigation-drawer
    class="primary"
    app
    light
    clipped
    fixed
    permanent
    expand-on-hover
    mini-variant
    width="350"
  >
    <v-list nav class="px-0 py-5">
      <v-list-item
        v-for="menuItem in menuItems"
        :key="menuItem.name"
        :to="{ name: menuItem.route, params: { role: 'supervisor' } }"
        class="ma-0"
      >
        <v-list-item-icon>
          <v-icon class="menubar-icon ml-2" size="22">
            fal {{ menuItem.icon }} fa-fw
          </v-icon>
          <v-badge
            v-if="menuItem.badge"
            :content="menuItem.badge"
            color="accent"
            overlap
            offset-x="10"
            offset-y="8"
          />
        </v-list-item-icon>
        <v-list-item-title class="white--text">{{
          menuItem.name
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SupervisorMenuBar',

  computed: {
    menuItems() {
      return [
        {
          name: this.$t('menu.start'),
          icon: 'fa-home',
          route: 'supervisor.dashboard',
          badge: null,
        },
        {
          name: this.$t('menu.fairs'),
          icon: 'fa-calendar-alt',
          route: 'supervisor.fairs',
          badge: null,
        },
        {
          name: this.$t('company.companies'),
          icon: 'fa-industry-alt',
          route: 'supervisor.companies',
          badge: null,
        },
        {
          name: this.$t('exhibitorSurvey.title'),
          icon: 'fa-square-poll-vertical',
          route: 'MultipleEvaluation',
          badge: null,
        },
      ]
    },
  },
}
</script>
