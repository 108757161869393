export default {
  exhibitorSurvey: {
    title: 'Exhibitor Survey',
    subtitle:
      'Thank you for participating in the international trade fair program of the BMWK/BMEL.',
    intro:
      'Your feedback helps us evaluate the international trade fair program. Answering the following questions will take about 5 minutes. All information will be treated confidentially.<br><br><b>Thank you for your assistance.</b>',
    tradeFairName: 'Trade Fair Title',
    tradeFairDate: 'Trade Fair Date',
    exhibitorName: 'Name of the Exhibiting Company',
    comments: 'Note/Comment',
    email: 'Email Address',
    emailHint:
      'Please provide your email address if you would like us to contact you regarding your feedback.',
    submitSurvey: 'Submit survey',
    startSurvey: 'Start survey',
    showSurvey: 'View survey',
    successMsg:
      'Thank you! You have submitted this survey on {submissionDate}.',
    successMsgWhenSubmitted:
      'Thank you! You have successfully submitted this survey.',
    continueButton: 'Continue',
    placerHolders: {
      textField: 'Your input',
      textArea: 'Your text input',
    },
    errors: {
      linkInvalid: 'The link is invalid.',
    },
    evaluation: {
      tradeFairDetailedView: 'Trade fair detailed view',
      overallEvaluation: 'Overall evaluation',
      selectPeriod: 'Select period',
      selectPeriodFrom: 'Period From',
      selectPeriodTo: 'Period To',
      buttonDialogClose: 'Close',
      buttonSearch: 'Evaluate',
      from: 'From',
      to: 'To',
      errorAtleastOneFilter: 'Please select at least one filter criterion.',
      errorNoResults: 'No results were found for the selected filter criteria.',
      errorSelectFair:'Please select a trade fair',
      numberSelectedTradeFairs: 'Number of selected trade fairs',
      numberQuestionedAnswers: 'Questioned/Answered',
      individualResult: 'Individual trade fair results',
      textInput: 'Text input',
      textInputs: 'Text inputs',
      exhibitor: 'Exhibitor',
      singleEvaluation: 'Single evaluation',
      contactPerson: 'Contact person',
      contactPersonEmail: 'Email contact person',
      submissionStatus: 'Submission status',
      surveySubmitted: 'Survey submitted',
      surveyNotSubmitted: 'Survey not yet submitted',
      surveyStartedAt: 'Start date',
      surveyStoppedAt: 'Stop date',
      surveyRunning: 'Survey is running',
      submittedCount: 'Number of submissions',
      atTheseTradefairs: 'Exhibitors at these trade fairs:',
      tookPartInSurvey: 'Of these, the following took part in the survey:',
    },
  },
}
