export default {
  help: {
    helpAndAdvice: 'Help and advice',
    search: 'Search',
    manageEntries: 'Manage help entries',
    helpEntries: 'Help entries',
    manageAndEditArticles: 'Manage and edit help articles.',
    answerQuestions: 'Answer questions',
    manageAndEditTopicSections:
      'Manage and edit topic sections, questions and answer texts.',
    textSearchOrID: 'Text search or ID',
    createNewCategory: 'Create a new category',
    newEntry: 'New entry',
    table: {
      active: 'Active',
      notActive: 'Not active',
      headline: 'Headline',
      summary: 'Summary',
    },
    action: {
      delete: 'delete entry',
      deleteEntry: 'Delete help entry',
    },
    form: {
      entryActive: 'Entry active',
      titleEN: 'Title english',
      titleDE: 'Title german',
      questionEN: 'Question english',
      questionDE: 'Question german',
      headlineDE: 'Headline german',
      headlineEN: 'Headline english',
      summaryDE: 'Summary german',
      summaryEN: 'Summary english',
      textDE: 'Text german',
      textEN: 'Text english',
    },
    modal: {
      germanEntry: 'German entry:',
      englishEntry: 'English entry:',
      newCategoryTitle: 'Create new category',
      newCategorySubtitle: 'Enter the titles of the new category in German and English',
      newEntryTitle: 'Create new entry',
      newEntrySubtitleTitle: 'Create a new bilingual entry with a brief summary',
    },
    notes: {
      notes: 'Notes',
      note: 'Note',
      noteSubject: 'Note subject',
      noteNew: 'New note for',
      noteFor: 'Notes for',
      noteBy: 'Note by',
      company: 'Notes for this Company',
      help: 'Here you can create and edit notes on a company. The applying business organisations, AUMA, BMWK, BMEL, BAFA, implementing companies and the administrators of leapfrog / w+t have read and write access to these notes. Accordingly, you will also see the notes entered by the other users. Please only include notes on the company itself. If possible, avoid specific personal data and organisational references to individual trade fair participations.',
    }
  },
}
