<template>
    <div>
        <div class="d-flex">
            <h3 class="mb-4 primary--text">
                {{ $t('exhibitorSurvey.evaluation.overallEvaluation') }}
            </h3>
            <v-spacer></v-spacer>
            <v-btn :to="{ name: 'SingleEvaluation' }" elevation="1" color="primary">{{
                $t('exhibitorSurvey.evaluation.tradeFairDetailedView')
            }}</v-btn>
        </div>

        <a href=""></a>
        <FilterBar @reportData="handleGetData" :isMultiple="true"></FilterBar>
        <v-row v-if="multipleEvaluationData.tradeFairs.length > 0">
            <v-col cols="4">
                <small>
                    {{ $t('exhibitorSurvey.evaluation.numberSelectedTradeFairs') }}:
                    {{ multipleEvaluationData.totals.tradefairs_count }}<br />
                    {{ $t('exhibitorSurvey.evaluation.atTheseTradefairs') }}
                    {{ multipleEvaluationData.totals.participations_count }}<br />
                    {{ $t('exhibitorSurvey.evaluation.tookPartInSurvey') }}
                    {{ multipleEvaluationData.totals.surveys_submitted_count }}
                </small>
            </v-col>
        </v-row>
        <template v-if="multipleEvaluationData.tradeFairs.length > 0">
            <Table
                v-for="surveyItem in multipleEvaluationData.survey_config"
                :key="surveyItem.id"
                :surveyItem="surveyItem"
                :questionNumber="surveyItem.included.numbering"
                :totals="multipleEvaluationData.totals"
                :tradeFairs="multipleEvaluationData.tradeFairs"
            ></Table>
        </template>
    </div>
</template>

<script>
import FilterBar from '@/components/common/ExhibitorSurvey/EvaluationFilter/FilterBar'
import Table from '@/components/common/ExhibitorSurvey/EvaluationFilter/Table.vue'

export default {
    name: 'MultipleEvaluation',

    components: {
        FilterBar,
        Table,
    },

    data() {
        return {
            exhibitorDialog: false,
            multipleEvaluationData: {
                survey_config: [],
                totals: {},
                tradeFairs: [],
            },
        }
    },

    methods: {
        handleGetData(data) {
            this.multipleEvaluationData.survey_config = []
            data.data.survey_config.forEach((element) => {
                if (
                    element.included.max_child_depth - element.included.depth == 1 ||
                    element.included.max_child_depth - element.included.depth == 0
                ) {
                    this.multipleEvaluationData.survey_config.push(element)
                } else {
                    if (element.included.children.some((item) => item.attributes?.select_type)) {
                        this.multipleEvaluationData.survey_config.push(element)
                        this.multipleEvaluationData.survey_config.push(...element.included.children)
                    } else {
                        element.included.children.forEach((child, index) => {
                            if (index === 0) {
                                this.multipleEvaluationData.survey_config.push({
                                    ...child,
                                    parent: element,
                                })
                            } else if (child.attributes.dependent_identifier) {
                                const independentParent = element.included.children.find(
                                    (children) => !children.attributes.dependent_identifier
                                )

                                const dependentChild = independentParent?.included?.children?.find(
                                    (children) =>
                                        children.attributes?.identifier === child.attributes.dependent_identifier
                                )

                                this.multipleEvaluationData.survey_config.push({
                                    ...child,
                                    dependentChild: dependentChild ?? null,
                                })
                            } else {
                                this.multipleEvaluationData.survey_config.push(child)
                            }
                        })
                    }
                }
            })
                 
            this.multipleEvaluationData.totals = data.data.totals
            this.multipleEvaluationData.tradeFairs = data.data.tradefairs
        },
    },
}
</script>
