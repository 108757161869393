<template>
  <div>
    <v-toolbar flat>
      <v-btn icon @click="hasHistory() ? $router.go(-1) : $router.push('/')">
        <v-icon>fal fa-angle-left</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-progress-linear
      v-if="form.loading" 
      size="100"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <v-container class="pa-8" fluid v-if="fair?.id">
      <trade-fair-card
        :fair="fair"
      />

      <v-expansion-panels
        class="mb-10 elevation-0 rounded-0"
        v-model="panels"
        multiple
        flat
      >

        <trade-fair-summary
            v-if="fair.attributes.summary_submitted_at"
            :fair="fair"
            :participations="fair.relationships.participations"
        />

        <interested-companies
            :trade-fair="fair"
        />

        <applications-list
          :fair="fair"
        />

        <online-registration-link
            v-if="isAdmin"
            :fair="fair"
        />

        <participation-and-conditions
          :company="company"
          :fair="fair"
          :exhibition-areas="fair.relationships.exhibitionAreas"
          :spaces="fair.relationships.spaces"
          :packages="fair.relationships.packages"
        />

        <execution-company
          :trade-fair="fair"
          :organizer="fair.relationships.organizer"
        />

        <fair-information :fair="fair" />
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TradeFairCard from '@/components/supervisor/TradeFair/TradeFairCard'
import OnlineRegistrationLink from '@/components/supervisor/TradeFair/OnlineRegistrationLink'
import ApplicationsList from '@/components/supervisor/TradeFair/ApplicationsList'
import InterestedCompanies from '@/components/supervisor/TradeFair/InterestedCompanies'
import TradeFairSummary from '@/components/supervisor/TradeFair/TradeFairSummary'
import ParticipationAndConditions from '@/components/common/TradeFair/ParticipationAndConditions'
import ExecutionCompany from '@/components/common/TradeFair/ExecutionCompany'
import FairInformation from '@/components/common/TradeFair/FairInformation'

export default {
  name: 'SupervisorFairView',

  components: {
    TradeFairCard,
    ApplicationsList,
    OnlineRegistrationLink,
    ParticipationAndConditions,
    ExecutionCompany,
    FairInformation,
    TradeFairSummary,
    InterestedCompanies
  },

  data() {
    return {
      fair: null,

      form: {
        loading: false,
      },

      panels: [],
    }
  },

  computed: {
    ...mapGetters('company', ['company']),
    ...mapGetters('auth', ['isAdmin']),
  },

  methods: {
    hasHistory() {
      return window.history.length > 2
    },

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.$route.params.id}`, {
        params: {
          'languages': '*',
          'include': [
            'accessUsers',
            'associations',
            'country',
            'exhibitionAreas',
            'industrySectors',
            'organizer',
            'packages',
            'services',
            'spaces',
            'type',
            'media',
            'interestedCompanies',
            'participations',
            'participationsCount',
          ],
        },
      })).data

      this.fair = response.data

      this.form.loading = false
    },
  },

  created() {
    this.load()
  },
}
</script>
