<template>
  <v-navigation-drawer
    class="primary"
    app
    light
    clipped
    fixed
    permanent
    expand-on-hover
    mini-variant
    width="350"
  >
    <v-list nav class="px-0 py-5">
      <v-list-item>
        <v-list-item-icon class="ml-2"> </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title white--text">
            <h5 v-if="!$wait.is(loaders.getTradeFairs)">
              <strong>{{ currentTradeFair.nameDisplay }}</strong>
            </h5>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-btn
              class="white--text mt-2"
              color="transparent"
              :to="{ name: 'organizer.dashboard' }"
              small
            >
              <v-icon class="white--text mr-2" size="16">
                fa fa-exchange fa-fw
              </v-icon>
              {{ $t('menu.changeProject') }}
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item
        v-for="menuItem in allowedItems"
        :key="menuItem.name"
        :to="{ name: menuItem.route, params: { role: 'organizer' } }"
        class="ma-0"
      >
        <v-list-item-icon>
          <v-icon class="menubar-icon ml-2" size="22">
            fal {{ menuItem.icon }} fa-fw
          </v-icon>
          <v-badge
            v-if="menuItem.badge"
            :content="menuItem.badge"
            color="accent"
            offset-x="10"
            offset-y="10"
          />
        </v-list-item-icon>
        <v-list-item-title class="white--text">
          {{ menuItem.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_TRADE_FAIRS } from '@/store/loading-types'

export default {
  name: 'OrganizerMenuBar',

  data() {
    return {
      participants: 0,
      loaders: {
        getTradeFairs: GET_TRADE_FAIRS,
      },
    }
  },

  computed: {
    ...mapGetters('organizer', ['currentTradeFair']),

    ...mapGetters('auth', ['isOrganizer']),

    allowedItems() {
      return this.menuItems.filter((item) => item.isAllowed)
    },

    menuItems() {
      return [
        {
          name: this.$t('menu.overview'),
          icon: 'fa-home',
          route: 'organizer.dashboard',
          badge: null,
          isAllowed: true,
        },
        {
          name: this.$t('menu.editRegistrationForm'),
          icon: 'fa-calendar-edit',
          route: 'organizer.application',
          badge: null,
          isAllowed: true,
        },
        {
          name: this.$t('menu.participantRegistration'),
          icon: 'fa-tasks',
          route: 'organizer.participants',
          badge: this.participants,
          isAllowed: true,
        },
        {
          name: this.$t('menu.tradeFairSummary'),
          icon: 'fa-flag-checkered',
          route: 'organizer.tradefairsummary',
          badge: null,
          isAllowed: this.$moment(this.currentTradeFair.startDate).isBefore(),
        },
        {
          name: this.$t('menu.interestedPersons'),
          icon: 'fa-user-check',
          route: 'organizer.interest',
          badge: null,
          isAllowed: true,
        },
        {
          name: this.$t('exhibitorSurvey.title'),
          icon: 'fa-square-poll-vertical',
          route: 'MultipleEvaluation',
          badge: null,
          isAllowed: true,
        },
        {
          name: this.$t('menu.sendNotification'),
          icon: 'fa-comment-lines',
          route: 'organizer.notifications.send',
          badge: null,
          isAllowed: true,
        },
        {
          name: this.$t('menu.userManagement'),
          icon: 'fa-users',
          route: 'organizer.users',
          badge: null,
          isAllowed: this.isOrganizer,
        },
        {
          name: this.$t('menu.settings'),
          icon: 'fa-cogs',
          route: 'organizer.settings',
          badge: null,
          isAllowed: true,
        },
      ]
    },
  },

  watch: {
    async currentTradeFair() {
      if (!this.currentTradeFair) {
        return
      }

      this.participants = (
        await this.$axios.get(`/api/trade-fairs/${this.currentTradeFair.id}`, {
          params: {
            languages: '*',
            include: ['participations'],
          },
        })
      ).data.data.relationships.participations.filter(
        (el) => el.status === 'submitted'
      ).length
    },
  },
}
</script>
