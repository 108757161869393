<template>
  <div>
    <v-app style="background-color: #f9f9f9">
      <div v-if="isAdmin">
        <admin-header />
        <admin-menu-bar />
      </div>
      <div v-else-if="isOrganizer || isProjectManager">
        <organizer-header />
        <organizer-menu-bar />
      </div>
      <div v-else>
        <supervisor-header />
        <supervisor-menu-bar />
      </div>

      <v-main>
        <v-container class="pa-6" fluid>
          <keep-alive :include="['MultipleEvaluation', 'SingleEvaluation']">
            <router-view></router-view>
          </keep-alive>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminHeader from '@/components/admin/Header'
import AdminMenuBar from '@/components/admin/MenuBar'
import OrganizerHeader from '@/components/organizer/Header'
import OrganizerMenuBar from '@/components/organizer/MenuBar'
import SupervisorHeader from '@/components/supervisor/Header'
import SupervisorMenuBar from '@/components/supervisor/MenuBar'

export default {
  name: 'Evaluation',

  components: {
    AdminHeader,
    AdminMenuBar,
    OrganizerHeader,
    OrganizerMenuBar,
    SupervisorHeader,
    SupervisorMenuBar,
  },

  computed: {
    ...mapGetters('auth', [
      'user',
      'isExhibitor',
      'isOrganizer',
      'isProjectManager',
      'isAdmin',
      'isAssociation',
    ]),
  },
}
</script>
