<template>
    <div>
        <v-row>
            <v-col cols="3">
                <v-autocomplete
                    :label="$t('tradeFair.filters.name')"
                    v-model="filters.selectedFair"
                    :items="sortedWithStartedSurveys"
                    :disabled="loading"
                    item-text="attributes.name"
                    item-value="id"
                    :no-data-text="$t('common.noDataAvailable')"
                    clearable
                    :multiple="isMultiple"
                    @click:clear="filters.selectedFair = []"
                    small-chips
                    hide-details
                    @change="!isMultiple ? handleSelectFair() : null"
                />
            </v-col>

            <v-col cols="3" v-if="showAllFilter">
                <v-autocomplete
                    :label="$t('tradeFair.executionCompany')"
                    v-model="filters.selectedOrganizers"
                    :items="organizers"
                    :disabled="loading"
                    item-text="name"
                    item-value="id"
                    multiple
                    :clearable="!isOrganizer && !isProjectManager"
                    small-chips
                    hide-details
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on" :disabled="isOrganizer || isProjectManager">
                            <v-list-item-action>
                                <v-checkbox :input-value="attrs.inputValue"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="3" v-if="showAllFilter">
                <v-text-field
                    :disabled="loading"
                    :label="$t('tradeFair.filters.year')"
                    readonly
                    small-chips
                    :value="formattedDateValue"
                    hide-details
                    clearable
                    @click="showDateDialog = true"
                    @click:clear="clearDate"
                    class="mt-0"
                >
                </v-text-field>
                <v-dialog v-model="showDateDialog" width="500">
                    <v-card elevation="2" class="pa-6">
                        <h3 class="mb-4">
                            {{ $t('exhibitorSurvey.evaluation.selectPeriod') }}
                        </h3>
                        <v-row>
                            <v-col>
                                <v-select
                                    :label="$t('tradeFair.filters.year')"
                                    v-model="filters.selectedDate.years"
                                    :items="years"
                                    multiple
                                    hide-details
                                    clearable
                                    dense
                                    chips
                                    @click:clear="filters.selectedDate.years = []"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row class="mb-16">
                            <v-col cols="6">
                                <date-input
                                    :label="$t('exhibitorSurvey.evaluation.selectPeriodFrom')"
                                    @changed="filters.selectedDate.startDate = $event"
                                    :value="filters.selectedDate.startDate"
                            /></v-col>
                            <v-col cols="6">
                                <date-input
                                    :label="$t('exhibitorSurvey.evaluation.selectPeriodTo')"
                                    @changed="filters.selectedDate.endDate = $event"
                                    :value="filters.selectedDate.endDate"
                            /></v-col>
                        </v-row>

                        <v-card-actions class="pa-0 pt-4">
                            <v-btn class="me-4" elevation="0" color="primary" @click="showDateDialog = false"
                                >ok
                            </v-btn>
                            <v-btn variant="text" @click="showDateDialog = false">{{
                                $t('exhibitorSurvey.evaluation.buttonDialogClose')
                            }}</v-btn>
                        </v-card-actions>
                    </v-card></v-dialog
                >
            </v-col>

            <v-col cols="3" v-if="showAllFilter">
                <v-autocomplete
                    :label="$t('tradeFair.filters.program')"
                    v-model="filters.selectedSponsors"
                    :items="sponsors"
                    :disabled="loading"
                    multiple
                    clearable
                    small-chips
                    hide-details
                />
            </v-col>

            <v-col cols="3" v-if="showAllFilter">
                <v-autocomplete
                    :label="$t('tradeFair.filters.country')"
                    v-model="filters.selectedCountries"
                    :items="countriesTitles"
                    :disabled="loading"
                    item-text="language"
                    item-value="id"
                    multiple
                    clearable
                    small-chips
                    hide-details
                />
            </v-col>

            <v-col cols="3" v-if="showAllFilter">
                <v-autocomplete
                    :label="$t('tradeFair.filters.association')"
                    v-model="filters.selectedAssociations"
                    :items="associations"
                    :disabled="loading"
                    item-text="name"
                    item-value="id"
                    multiple
                    :clearable="!isAssociation"
                    small-chips
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on" :disabled="isAssociation">
                            <v-list-item-action>
                                <v-checkbox :input-value="attrs.inputValue"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex align-center">
                <v-btn
                    class="mb-5"
                    color="secondary"
                    outlined
                    :loading="loading"
                    @click="loadSurveyReport"
                    v-if="isMultiple"
                    >{{ $t('exhibitorSurvey.evaluation.buttonSearch') }}</v-btn
                >
                <v-btn color="secondary" outlined :loading="showButtonLoading" @click="loadParticipations" v-else>{{
                    $t('exhibitorSurvey.evaluation.buttonSearch')
                }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="showError">
            <v-col>
                <p class="error--text">
                    {{ showError }}
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { SPONSORS } from '@/enums/sponsors'
import { mapGetters, mapActions } from 'vuex'
import DateInput from './DateInput.vue'

export default {
    props: {
        isMultiple: {
            type: Boolean,
            default: false,
        },
        fairs: {
            type: Array,
        },
        showAllFilter: {
            type: Boolean,
            default: true,
        },
        selectedSingleFair: {
            type: Number,
        },
        showButtonLoading: {
            type: Boolean,
        },
    },
    components: {
        DateInput,
    },
    data() {
        return {
            loading: false,
            preventFetch: false,
            showError: '',
            showDateDialog: false,
            filters: {
                selectedFair: [],
                selectedDate: {
                    startDate: '',
                    endDate: '',
                    years: [],
                },
                selectedAssociations: [],
                selectedCountries: [],
                selectedOrganizers: [],
                selectedSponsors: [],
            },
        }
    },

    computed: {
        ...mapGetters('auth', ['user', 'isExhibitor', 'isOrganizer', 'isProjectManager', 'isAdmin', 'isAssociation']),
        ...mapGetters('countries', ['countries']),
        ...mapGetters('association', ['associations']),
        ...mapGetters('organizer', ['organizers']),
        ...mapGetters('tradefair', ['sortedWithStartedSurveys']),

        sponsors() {
            return [
                { text: this.$t('forms.sponsors.bmwi'), value: SPONSORS.BMWI },
                { text: this.$t('forms.sponsors.bmel'), value: SPONSORS.BMEL },
            ]
        },

        years() {
            const startYear = 2025
            const currentYear = new Date().getFullYear()
            const years = []

            for (let i = startYear; i <= currentYear; i++) {
                years.push(i)
            }

            return years
        },

        countriesTitles() {
            return this.countries.map((country) => {
                return {
                    id: country.id,
                    language: country.translations?.[this.$i18n.locale]?.country,
                }
            })
        },

        minimumOneFilterApplied() {
            const hasMainFilters =
                this.filters.selectedOrganizers.length > 0 ||
                this.filters.selectedSponsors.length > 0 ||
                this.filters.selectedCountries.length > 0 ||
                this.filters.selectedAssociations.length > 0 ||
                this.filters.selectedDate.years.length > 0 ||
                this.filters.selectedDate.startDate ||
                this.filters.selectedDate.endDate

            const hasSelectedFair = Array.isArray(this.filters.selectedFair)
                ? this.filters.selectedFair.length > 0
                : !!this.filters.selectedFair

            return hasMainFilters || hasSelectedFair
        },

        formattedDateValue() {
            const { years, startDate, endDate } = this.filters.selectedDate

            if (years?.length > 0 && !startDate && !endDate) {
                return years.join(', ')
            }

            if (startDate && !endDate) {
                return `${this.$t('exhibitorSurvey.evaluation.from')} ${this.$root.$options.filters.dateByLocale(
                    startDate
                )}`
            }

            if (!startDate && endDate) {
                return `${this.$t('exhibitorSurvey.evaluation.to')} ${this.$root.$options.filters.dateByLocale(
                    endDate
                )}`
            }

            if (startDate && endDate) {
                return `${this.$root.$options.filters.dateByLocale(
                    startDate
                )} - ${this.$root.$options.filters.dateByLocale(endDate)}`
            }

            return ''
        },
    },

    async created() {
        await Promise.all([
            this.fetchCountries(),
            this.fetchAssociations(),
            this.fetchOrganizers(),
            this.fetchFairsWithStartedSurveys(),
        ])

        if ((this.user?.organizer?.id && this.isOrganizer) || this.isProjectManager) {
            this.filters.selectedOrganizers = [this.user.organizer.id]
        }

        if (this.user?.association?.id && this.isAssociation) {
            this.filters.selectedAssociations = [this.user.association.id]
        }

        if (this.$route.query.id) {
            if (!this.isMultiple) {
                this.filters.selectedFair = parseInt(this.$route.query.id)
            }
        }
    },

    methods: {
        ...mapActions('countries', ['fetchCountries']),
        ...mapActions('association', ['fetchAssociations']),
        ...mapActions('organizer', ['fetchOrganizers']),
        ...mapActions('tradefair', ['fetchFairsWithStartedSurveys']),

        loadSurveyReport() {
            this.showError = ''
            if (!this.minimumOneFilterApplied) {
                this.showError = this.$t('exhibitorSurvey.evaluation.errorAtleastOneFilter')
                return
            }
            this.loading = true

            const dateRange = this.dateToSend(this.filters.selectedDate.startDate, this.filters.selectedDate.endDate)

            const params = {
                languages: '*',
                include: ['tradefair_details'],

                'filter[survey_started]': 1,
                'filter[tradefair_id]': this.isMultiple
                    ? this.combineArraysValue(this.filters.selectedFair)
                    : this.filters.selectedFair,
                'filter[organizer_id]': this.combineArraysValue(this.filters.selectedOrganizers),
                'filter[sponsor]': this.combineArraysValue(this.filters.selectedSponsors),
                'filter[country_id]': this.combineArraysValue(this.filters.selectedCountries),
                'filter[years]': this.combineArraysValue(this.filters.selectedDate.years),
                'filter[association_id]': this.combineArraysValue(this.filters.selectedAssociations),
            }

            if (dateRange) {
                params['filter[starts_between]'] = dateRange
            }

            this.$axios
                .get('/api/survey/report', {
                    params: params,
                })
                .then((response) => {
                    const data = response.data

                    if (!data.data.tradefairs.length) {
                        this.showError = this.$t('exhibitorSurvey.evaluation.errorNoResults')
                    }
                    this.$emit('reportData', data)
                    this.loading = false
                })
                .catch((error) => {
                    console.log(error.response ? error.response.data.error : error.message)
                })
        },

        handleSelectFair() {
            this.preventFetch = true
            if (!this.isMultiple) {
                if (this.filters.selectedFair !== parseInt(this.$route.query.id)) {
                    this.$router.push({
                        name: 'SingleEvaluation',
                        query: { id: this.filters.selectedFair },
                    })
                }
            }
        },

        combineArraysValue(array) {
            return array.length === 1 ? `${array[0]},` : array.join(',')
        },

        clearDate() {
            this.filters.selectedDate.years = []
            this.filters.selectedDate.startDate = ''
            this.filters.selectedDate.endDate = ''
        },

        dateToSend(startDate, endDate) {
            if (startDate && endDate) {
                return `${startDate},${endDate}`
            } else if (startDate && !endDate) {
                return `${startDate},`
            } else if (!startDate && endDate) {
                return `,${endDate}`
            } else {
                return ''
            }
        },

        loadParticipations() {
            this.showError = ''
            if (!this.filters.selectedFair) {
                this.showError = this.$t('exhibitorSurvey.evaluation.errorSelectFair')
            }
            this.$emit('loadParticipations')
        },
    },

    watch: {
        minimumOneFilterApplied: {
            handler(newValue) {
                if (newValue) {
                    this.showError = ''
                }
            },
            deep: true,
        },

        '$route.query.id': {
            immediate: true,
            handler(newId) {
                if (!this.isMultiple) {
                    this.filters.selectedFair = newId
                    if (!this.preventFetch) {
                        this.$emit('loadParticipations')
                    }
                    this.preventFetch = false
                }
            },
        },

        $route: {
            immediate: true,
            handler() {
                this.showError = ''
            },
        },
    },
}
</script>
