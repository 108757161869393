import { api } from '@/boot/api'
import axios from "axios";
import {
  SET_TRADE_FAIRS,
  SET_TRADE_FAIR,
  SET_ASSIGNED_TRADE_FAIRS,
  SET_TRADE_FAIR_PACKAGES,
  SET_TRADE_FAIR_AREAS,
  SET_TRADE_FAIR_SPACES,
  SET_TRADE_FAIR_SERVICES,
  SET_TRADE_FAIRS_WITH_SURVEYS,
  SET_INDUSTRY_SECTORS,
  SET_COUNTRIES,
  SET_YEARS,
  SET_ERROR_MESSAGE,
  SET_ASSOCIATIONS
} from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'
import { getCurrentLocale } from '@/utilities/helpers/i18n'

const defaultState = () => ({
  errorMessage: '',
  tradeFairs: [],
  tradeFair: {},
  fairsWithStartedSurveys:[],
  assignedTradeFairs: [],
  packages: [],
  areas: [],
  spaces: [],
  services: [],
  industrySectors: [],
  countries: [],
  associations: [],
  years: [],
  isTradeFairsFilters: false,
})

const state = defaultState()

const actions = {
  async fetchAllTradeFairs({ commit }) {
    try {
      let data = await api.get('/trade-fairs')

      commit(SET_TRADE_FAIRS, data)
      commit(SET_INDUSTRY_SECTORS, data)
      commit(SET_COUNTRIES, data)
      commit(SET_ASSOCIATIONS, data)
      commit(SET_YEARS, data)

      return true
    } catch (error) {
      console.error('API error (fetch all trade fairs): ', error)
      return false
    }
  },

  async fetchTradeFair({ commit, state }, tradeFairID) {
    try {
      if (state.tradeFair.id === tradeFairID) {
        return true
      }

      if (!tradeFairID) {
        console.error(`Invalid Trade Fair ID: ${tradeFairID}`)
        return false
      }

      const tradeFair = await api.get(`/trade-fairs/${tradeFairID}`)

      commit(SET_TRADE_FAIR, tradeFair)
      commit(SET_TRADE_FAIR_SPACES, tradeFair.spaces)
      commit(SET_TRADE_FAIR_PACKAGES, tradeFair.packages)
      commit(SET_TRADE_FAIR_SERVICES, tradeFair.services)
      commit(SET_TRADE_FAIR_AREAS, tradeFair.exhibitionAreas)

      return true
    } catch (error) {
      console.error('API error (fetch trade fair): ', error)
      return false
    }
  },

  async fetchFairsWithStartedSurveys({ commit, state }) {
    try {
      if (state.fairsWithStartedSurveys.length > 0) {
        return  
      }
      const response = await axios
      .get('/api/trade-fairs' ,{
        params: {
          languages: '*',
          'filter[survey_started]': 1, 
          fields: [
            'tradefairs.name',
            'tradefairs.start_date',
            'tradefairs.organizer_id',
            'tradefairs.sponsor',
            'tradefairs.country_id',
            'tradefairs.auma_id',
          ],
        },
      });
      commit(SET_TRADE_FAIRS_WITH_SURVEYS, response.data.data)
      return true;
  
    } catch (error) {
      console.error('API error (fetch trade fairs with surveys): ', error)
      return false;
    }
  },


  async fetchPackages({ commit, state }, tradeFairID) {
    try {
      if (!tradeFairID) {
        tradeFairID = state.tradeFair.id
      }

      if (!tradeFairID) {
        console.error(`Invalid Trade Fair ID: ${tradeFairID}`)
        return false
      }

      const packages = await api.get(`/trade-fairs/${tradeFairID}/packages`)

      commit(SET_TRADE_FAIR_PACKAGES, packages)

      return true
    } catch (error) {
      console.error('API error (fetch trade fair packages): ', error)
      return false
    }
  },

  async fetchAreas({ commit, state }, tradeFairID) {
    try {
      if (!tradeFairID) {
        tradeFairID = state.tradeFair.id
      }

      if (!tradeFairID) {
        console.error(`Invalid Trade Fair ID: ${tradeFairID}`)
        return false
      }

      const areas = await api.get(`/trade-fairs/${tradeFairID}/areas`)

      commit(SET_TRADE_FAIR_AREAS, areas)

      return true
    } catch (error) {
      console.error('API error (fetch trade fair areas): ', error)
      return false
    }
  },

  async fetchSpaces({ commit }, tradeFairID) {
    try {
      if (!tradeFairID) {
        console.error(`Invalid Trade Fair ID: ${tradeFairID}`)
        return false
      }

      const spaces = await api.get(`/trade-fairs/${tradeFairID}/spaces`)

      commit(SET_TRADE_FAIR_SPACES, spaces)

      return true
    } catch (error) {
      console.error('API error (fetch trade fair spaces): ', error)
      return false
    }
  },

  async fetchAssignedTradeFairs({ commit, rootGetters }) {
    try {
      const user = rootGetters['auth/user']
      if (!user.id) {
        return false
      }

      const fairs = await api.get(`/users/${user.id}/trade-fairs`)

      commit(SET_ASSIGNED_TRADE_FAIRS, fairs)

      return true
    } catch (error) {
      console.error('API error (fetch assigned trade fairs): ', error)
      return false
    }
  },

  async fetchServices({ commit, state }, tradeFairID) {
    try {
      if (!tradeFairID) {
        tradeFairID = state.tradeFair.id
      }

      if (!tradeFairID) {
        console.error(`Invalid Trade Fair ID: ${tradeFairID}`)
        return false
      }

      const services = await api.get(`/trade-fairs/${tradeFairID}/services`)

      commit(SET_TRADE_FAIR_SERVICES, services)

      return true
    } catch (error) {
      console.error('API error (fetch trade fair services): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async fetchParticipations({  }, tradeFairID) {
    try {
      if (!tradeFairID) {
        console.error(`Invalid Trade Fair ID: ${tradeFairID}`)
        return false
      }

      const participants = await api.get(`/trade-fairs/${tradeFairID}/participants`)

      return participants
    } catch (error) {
      console.error('API error (fetch trade fair services): ', error)
      return false
    }
  },

  setNotedTradeFairs({state}, tradeFairsId) {
    state.tradeFairs.map((el) => {
      el.isFavorite = tradeFairsId.includes(el.id);
      return el;
    })

    return true;
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadSummary({}, {tradeFair}) {
    try {
      await api.get(`trade-fairs/${tradeFair.id}/export-summary`,
          {},
          {file: {type: 'pdf'}},
          {responseType: 'blob'}
      )

      return true
    } catch (error) {
      console.error('API error (fetch summary): ', error)
      return false
    }
  },

  async reopenConfigurationForm({ dispatch }, payload) {
    try {
      await api.post(`/trade-fairs/${payload.tradeFairId}/reopen-configuration`, null, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      dispatch('fetchAllTradeFairs')

      return true
    } catch (error) {
      console.error('API error (reopen trade fair configuration form): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async hide({  }, payload) {
    try {
      await api.put(`/trade-fairs/${payload.tradeFairId}/hide`, null, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      return true
    } catch (error) {
      console.error('API error (reopen trade fair configuration form): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async unhide({  }, payload) {
    try {
      await api.put(`/trade-fairs/${payload.tradeFairId}/unhide`, null, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      return true
    } catch (error) {
      console.error('API error (reopen trade fair configuration form): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadTradeFairParticipants({  }, { id, name }) {
    try {
      if (!id) {
        console.error(`Invalid Trade Fair ID: ${id}`)
        return false
      }

      await api.get(`/trade-fairs/${id}/export-participant?lang=${getCurrentLocale()}`,
          {},
          {file: {name: name ?? 'participants', type:  'xlsx'}},
          {responseType: 'blob'}
      )

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadParticipantsSupervisorXlsx({  }, { id, name }) {
    try {
      if (!id) {
        console.error(`Invalid Trade Fair ID: ${id}`)
        return false
      }

      await api.get(`/trade-fairs/${id}/export-participant-supervisor-xlsx`,
          {},
          {file: {name: name ?? 'participants', type:  'xlsx'}},
          {responseType: 'blob'}
      )

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadParticipantsSupervisorPdf({  }, { id, name }) {
    try {
      if (!id) {
        console.error(`Invalid Trade Fair ID: ${id}`)
        return false
      }

      await api.get(`/trade-fairs/${id}/export-participant-supervisor-pdf`,
          {},
          {file: {name: name ?? 'participants', type:  'pdf'}},
          {responseType: 'blob'}
      )

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_ERROR_MESSAGE] (state, message) {
    state.errorMessage = message
  },

  [SET_TRADE_FAIRS] (state, tradeFairs) {
    state.tradeFairs = tradeFairs
  },

  [SET_TRADE_FAIR] (state, tradeFair) {
    state.tradeFair = tradeFair
  },

  [SET_ASSIGNED_TRADE_FAIRS] (state, tradeFairs) {
    state.assignedTradeFairs = tradeFairs
  },

  [SET_TRADE_FAIR_PACKAGES] (state, packages) {
    state.packages = packages
  },

  [SET_TRADE_FAIR_AREAS] (state, areas) {
    state.areas = areas
  },

  [SET_TRADE_FAIR_SPACES] (state, spaces) {
    state.spaces = spaces
  },

  [SET_TRADE_FAIR_SERVICES] (state, services) {
    state.services = services
  },

  [SET_TRADE_FAIRS_WITH_SURVEYS] (state,fairsWithStartedSurveys){
    state.fairsWithStartedSurveys = fairsWithStartedSurveys
  },

  [SET_INDUSTRY_SECTORS] (state, tradeFairs) {
    let sectors = {};

    tradeFairs.map(function (fair) {
      fair.sectors.map(function (sector) {
        sectors[sector.id] = {
          id: sector.id,
          sector: sector.name
        }
      })
    });

    state.industrySectors = Object.values(sectors);
  },

  [SET_COUNTRIES](state, tradeFairs) {
    let countries = {}

    tradeFairs.map(function (item) {
      if (!item.country) return;
      countries[item.country.id] = {
        id: item.country.id,
        country: item.country.country,
      }
    })

    state.countries = Object.values(countries)
  },

  [SET_ASSOCIATIONS](state, tradeFairs) {
    let associations = {}

    tradeFairs.forEach(function (tradeFair) {
      for (var item of tradeFair.associations) {
        associations[item.id] = {
          id: item.id,
          association: item.name,
        }
      }
    })

    state.associations = Object.values(associations)
  },

  [SET_YEARS] (state, tradeFairs) {
    let years = {}

    tradeFairs.map(function (item) {
      years[item.year] = item.year
    })

    state.years = Object.values(years)
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  sectors: state => state.industrySectors,

  sponsors: state => [...new Set(state.tradeFairs.map(tradeFair => tradeFair.sponsor))],

  organizers: state => [...new Set(state.tradeFairs.filter(tradeFair => tradeFair.organizer).map(tradeFair => tradeFair.organizer.name))],

  sortedTradeFairs: state => [...state.tradeFairs].sort((a, b) => a.name.localeCompare(b.name)),

  sortedWithStartedSurveys: state => [...state.fairsWithStartedSurveys].sort((a, b) => a.attributes.name.localeCompare(b.attributes.name)),

  coexhibitorFee: state => !isNaN(state.tradeFair.coexhibitorFee) ? parseFloat(state.tradeFair.coexhibitorFee) : 0,

  coexhibitorExternalFee: state => !isNaN(state.tradeFair.coexhibitorExternalFee) ? parseFloat(state.tradeFair.coexhibitorExternalFee) : 0,

  coexhibitorExternalFee2: state => !isNaN(state.tradeFair.coexhibitorExternalFee2) ? parseFloat(state.tradeFair.coexhibitorExternalFee2) : 0,

  fees: state => state.services.filter(service => service.fee),

  singleSelectableItems: state => state.services.filter(service => !service.fee && !service.multiselectable),

  multiSelectableItems: state => state.services.filter(service => !service.fee && service.multiselectable),

}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
