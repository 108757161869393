<template>
  <v-expansion-panel class="elevation-1 rounded-0">
    <v-dialog v-model="modalOpenMoreThenFive" max-width="800px">
      <v-card>
        <v-card-title class="justify-space-between">
          <h4 class="primary--text">
            {{ $t('tables.participation.form.priceUnsponsored') }}
          </h4>
          <v-icon size="17" @click="closeModalMoreThenFive">fal fa-times</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <p class="mt-5" v-html="$t('tables.participation.form.priceUnsponsoredHelp')">
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" @click="closeModalMoreThenFive">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-expansion-panel-header>
      <h3>{{ $t('tables.participation.form.participationAndConditions') }}</h3>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <p class="mb-1">
        <a href="" @click.prevent="handleExportSummary">
          {{ $t('participation.configureRegistrationForm.downloadSummary') }}
        </a>
      </p>
      <v-row v-if="fair.attributes.registration_type === 'print'">
        <v-col cols="12">
          <p class="mb-0">
            {{ $t('tables.participation.form.informationOnTheConditionsAndParticipation') }}
            <a
              :href="fair.attributes.link_auma_database"
              target="_blank"
              v-html="fair.attributes.name_display"
            ></a>
          </p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-show="fair.relationships.packages?.length > 0"
          cols="12"
          class="pr-5"
          v-if="fair.attributes.booking_type_package"
        >
          <p class="mb-0">
            <strong>{{ $t('tables.participation.form.packagesPrices') }}</strong>
          </p>
          <v-row>
            <v-col cols="12">
              <table class="table-costs">
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('tables.tradeFair.name') }}
                    </th>
                    <th>
                      {{ $t('tables.participation.form.priceSponsored') }}
                    </th>
                    <th>
                      {{ $t('tables.participation.form.priceUnsponsored') }}
                      <v-icon
                        size="18"
                        style="cursor: pointer; width: 18px; height: 18px"
                        class="align-start primary--text mb-1 ml-1"
                        @click="openModalMoreThenFive"
                        >
                          fal fa-question-circle
                      </v-icon>
                    </th>
                    <th>
                      {{ $t('tables.participation.form.pricePublicsector') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="packag in fair.relationships.packages" :key="packag.id">
                    <td v-html="packag.attributes.title[$i18n.locale]"></td>
                    <td>{{ packag.attributes.price_sponsored | money }}</td>
                    <td>{{ packag.attributes.price_unsponsored | money }}</td>
                    <td>{{ packag.attributes.price_publicsector | money }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="fair.attributes.booking_type_space">
          <p class="mb-0">
            <strong>{{ $t('tables.participation.form.standSizesAndPrices') }}</strong>
          </p>
          <v-row>
            <v-col cols="12">
              <table class="table-costs">
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('forms.fields.size') }}
                    </th>
                    <th>
                      {{ $t('tables.participation.form.priceSponsored') }}
                    </th>
                    <th>
                      {{ $t('tables.participation.form.priceUnsponsored') }}
                      <v-icon
                        size="18"
                        style="cursor: pointer; width: 18px; height: 18px"
                        class="align-start primary--text mb-1 ml-1"
                        @click="openModalMoreThenFive"
                        >
                          fal fa-question-circle
                      </v-icon>
                    </th>
                    <th>
                      {{ $t('tables.participation.form.pricePublicsector') }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="space in fair.relationships.spaces" :key="space.id">
                    <td>
                      {{ space.attributes.size_from + '-' + space.attributes.size_to }}
                      <span v-html="$t('tables.participation.form.qm')" />
                    </td>
                    <td>{{ space.attributes.price_sponsored | money }}</td>
                    <td>{{ space.attributes.price_unsponsored | money }}</td>
                    <td>{{ space.attributes.price_publicsector | money }}</td>
                    <td
                      v-html="
                        $t(space.attributes.construction_included ? 'tables.participation.form.pricePerM2withStandConstruction' : 'tables.participation.form.pricePerM2withoutStandConstruction')
                      "
                    />
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" v-if="fair.attributes.booking_type_space">
          <p class="pb-0 mb-0">
            {{ $t('tables.participation.form.minimumArea') }}:
            {{ fair.attributes.space_min_size }}
            <span v-html="$t('tables.participation.form.qm')" /><br />
            {{ $t('tables.participation.form.maximumArea') }}:
            {{ fair.attributes.space_max_size }}
            <span v-html="$t('tables.participation.form.qm')" />
          </p>
        </v-col>
        <v-col cols="6" v-if="hasSubexhibitorAllowed">
          <strong>{{ $t('tables.participation.form.fees') }}</strong>
          <p class="mb-0">
            <span v-if="fair.attributes.coexhibitor_fee">
              {{ fair.attributes.coexhibitor_fee_title[$i18n.locale] }}:
              {{ fair.attributes.coexhibitor_fee | money }} <br/>
            </span>
            <br />
            <span v-if="fair.attributes.coexhibitor_external_fee">
              {{ $t('forms.fields.coexhibitorExternalFee') + ':' }}<br />
            </span>
            <span v-if="fair.attributes.coexhibitor_external_fee">
              {{ fair.attributes.coexhibitor_external_fee_title[$i18n.locale] }}:
              {{ fair.attributes.coexhibitor_external_fee | money }}
            </span>
            <br />
            <span v-if="fair.attributes.coexhibitor_external_fee2">
              {{ fair.attributes.coexhibitor_external_fee2_title[$i18n.locale] }}:
              {{ fair.attributes.coexhibitor_external_fee2 | money }}
            </span>
          </p>
        </v-col>
        <v-col v-if="!fair.relationships.services?.length == 0" cols="6">
          <p class="pt-0">
            <strong>{{ $t('participation.equipment.title') }}</strong>
          </p>
          <div
            v-for="service in fair.relationships.services"
            :key="service.id"
          >
            <p>
              <b>{{ service.attributes.title[$i18n.locale] }}</b>
              <small v-if="service.attributes.mandatory">
                ({{ $t('tables.participation.form.mandatoryFee') }})</small
              >
              <small v-else>
                ({{ $t('tables.participation.form.optionalFee') }})</small
              >
              <br />
              <span
                v-html="service.attributes.description[$i18n.locale]"
              ></span>
              {{ service.attributes.price | money }}<br />
            </p>
          </div>
        </v-col>

        <v-col v-if="fair.relationships.exhibition_areas.length > 0" cols="6">
          <p>
            <strong>
              {{ $t('tables.participation.form.exhibitionAreasOfGermanParticipation') }}
            </strong>
          </p>

          <ul>
            <li v-for="area in fair.relationships.exhibition_areas" :key="area.id">
              {{ area.attributes.name[$i18n.locale] }}
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-row v-if="fair.attributes.registration_type === 'online'">
        <v-col cols="12">
          <p
            class="mb-1"
          >
            <strong>
              {{ $t('tables.participation.form.conditionsOfParticipation') }}
            </strong>
          </p>
          <p class="mb-1">
            <a :href="$t('common.atbLink')" target="_blank">
              {{
                $t(
                  'tables.participation.form.generalConditionsOfParticipation'
                )
              }}, PDF
            </a>
          </p>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {mapActions} from 'vuex'
import {REGISTRATION_TYPES} from '@/enums/registrationTypes'

export default {
  name: 'TradeFairParticipationAndConditions',

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      REGISTRATION_TYPES,
      modalOpenMoreThenFive: false
    }
  },

  methods: {
    ...mapActions('tradefair', [
      'downloadSummary',
    ]),

    handleExportSummary() {
      this.downloadSummary({tradeFair: this.fair});
    },

    openModalMoreThenFive() {
      this.modalOpenMoreThenFive = true
    },
    closeModalMoreThenFive() {
      this.modalOpenMoreThenFive = false
    },
  },

  computed: {
    hasSubexhibitorAllowed() {
      const packages = this.fair?.relationships?.packages || [];
      const spaces = this.fair?.relationships?.spaces || [];
      const bookingTypePackage = this.fair?.attributes?.booking_type_package === 1;
      const bookingTypeSpace = this.fair?.attributes?.booking_type_space === 1;

      return (
        (bookingTypePackage && packages.some(pkg => pkg.attributes?.allow_subexhibitor === 1)) ||
        (bookingTypeSpace && spaces.some(space => space.attributes?.allow_subexhibitor === 1))
      );
    }
  }
}
</script>
