<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">{{ $t('tradeFair.tradeFairs') }}</h4>
      </v-card-title>

      <v-divider />

      <fair-search v-model="filters"></fair-search>

      <v-divider />

      <v-data-table
        :headers="headers"
        :loading="form.loading"
        :items="fairs"
        :page.sync="form.page"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
          showFirstLastPage: true,
        }"
        :server-items-length="form.total"
        show-expand
      >
        <template v-slot:item.name="{ item }">
          <span v-if="item.attributes.name_display">{{
            item.attributes.name_display
          }}</span>
        </template>

        <template v-slot:item.registration_type="{ item }">
          <registration-status-chip :tradefair="item.attributes" />
        </template>

        <template v-slot:item.organizer="{ item }">
          <span v-if="item.relationships.organizer">
            {{ item.relationships.organizer.name }}
          </span>
          <span v-else>
            {{ $t('tables.tradeFair.notYetKnown') }}
          </span>
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.attributes.city }},
          {{ item.relationships.country?.translations[$i18n.locale].country }}
        </template>

        <template v-slot:item.registration_deadline="{ item }">
          <span v-if="item.attributes.registration_deadline">
            {{ item.attributes.registration_deadline | dateByLocale }}
          </span>
          <span v-else>
            {{ $t('tables.tradeFair.notYetKnown') }}
          </span>
        </template>

        <template v-slot:item.start_date="{ item }">
          {{ item.attributes.start_date_display[$i18n.locale] }}
        </template>

        <template v-slot:item.sponsor="{ item }">
          {{ $t(`forms.sponsors.${item.attributes.sponsor}`) }}
        </template>

        <template v-slot:item.association="{ item }">
          {{
            item.relationships.associations
              .map((el) => el.translations[$i18n.locale].name)
              .join(' / ')
          }}
        </template>

        <template v-slot:item.participation_count="{ item }">
          {{ item.included.participations_count }}
        </template>

        <template v-slot:item.interested_companies_count="{ item }">
          {{ item.included.interested_companies_count }}
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length" class="elevation-1 px-1 grey lighten-5">
            <v-container fluid>
              <v-row>
                <v-col cols="6" class="px-7">
                  <router-link
                    :to="{ name: 'admin.fair', params: { id: item.id } }"
                  >
                    {{ $t('tables.tradeFair.details') }}
                  </router-link>

                  <p class="mt-2">
                    <strong>{{ $t('tables.tradeFair.execution') }}</strong>
                  </p>
                  <table class="default-table">
                    <tr>
                      <td>{{ $t('tables.tradeFair.participationForm') }}</td>
                      <td>
                        {{
                          item.attributes.type
                            ? item.attributes.type.type[$i18n.locale]
                            : ''
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tables.tradeFair.association') }}</td>
                      <td>
                        {{
                          item.relationships.associations
                            .map((el) => el.translations[$i18n.locale].name)
                            .join(' / ')
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('participation.confirmed') }}</td>
                      <td>{{ item.included.participations_count }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('tradeFair.executionCompany') }}</td>
                      <td>
                        <span v-if="item.relationships.organizer">
                          {{ item.relationships.organizer.name }}
                        </span>
                        <span v-else>
                          {{ $t('tables.tradeFair.notYetKnown') }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('participation.contactPerson') }}</td>
                      <td>{{ item.attributes.contact_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('participation.phone') }}</td>
                      <td>{{ item.attributes.contact_phone }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('forms.fields.email') }}</td>
                      <td>{{ item.attributes.contact_email }}</td>
                    </tr>
                  </table>

                  <p class="mt-5">
                    <strong>{{ $t('tradeFair.assignedUsers') }}</strong>
                  </p>
                  <p></p>
                  <table class="default-table">
                    <tr>
                      <th class="text-left">{{ $t('users.name') }}</th>
                      <th class="text-left">{{ $t('auth.email') }}</th>
                    </tr>
                    <tr
                      v-for="(user, i) in item.relationships.access_users"
                      :key="i"
                    >
                      <td>{{ user.first_name }} {{ user.last_name }}</td>
                      <td>{{ user.email }}</td>
                    </tr>
                  </table>

                  <p class="mt-5" v-if="item.attributes.survey_started_at">
                    <strong>{{ $t('exhibitorSurvey.title') }}</strong>
                    <v-btn
                      icon
                      x-small
                      class="ml-2"
                      @click="goToFairEvaluation(item.id, 'admin')"
                    >
                      <v-icon class="mt-1" color="primary"
                        >fa-solid fa-square-arrow-up-right</v-icon
                      >
                    </v-btn>
                  </p>
                  <table
                    class="default-table"
                    v-if="item.attributes.survey_started_at"
                  >
                    <tr>
                      <th class="text-left">
                        {{ $t('exhibitorSurvey.evaluation.surveyStartedAt') }}
                      </th>
                      <th class="text-left">
                        {{ $t('exhibitorSurvey.evaluation.surveyStoppedAt') }}
                      </th>
                      <th class="text-left">
                        {{ $t('exhibitorSurvey.evaluation.submittedCount') }}
                      </th>
                    </tr>
                    <tr>
                      <td>
                        {{
                          formatDateByLocale(item.attributes.survey_started_at)
                        }}
                      </td>
                      <td v-if="item.attributes.survey_stopped_at">
                        {{
                          formatDateByLocale(item.attributes.survey_stopped_at)
                        }}
                      </td>
                      <td v-else>
                        <span>{{
                          $t('exhibitorSurvey.evaluation.surveyRunning')
                        }}</span>
                      </td>
                      <td>
                        <span>{{ item.included.survey_submitted_count }}/{{ item.included.participations_count }}</span>
                      </td>
                    </tr>
                  </table>

                  <p class="mt-5">
                    <strong>{{ $t('tradeFair.dates') }}</strong>
                  </p>
                  <table class="default-table">
                    <tr>
                      <td>{{ $t('forms.fields.name') }}</td>
                      <td>{{ item.attributes.name_display }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('tables.tradeFair.startAt') }}</td>
                      <td>
                        {{ item.attributes.start_date_display[$i18n.locale] }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tables.tradeFair.endAt') }}</td>
                      <td>{{ item.attributes.end_date | dateByLocale }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('forms.fields.website') }}</td>
                      <td>
                        <a target="_blank" :href="item.attributes.website">{{
                          item.attributes.website
                        }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tradeFair.gtq') }}</td>
                      <td>
                        {{
                          item.attributes.is_gtq
                            ? $t('common.yes')
                            : $t('common.no')
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tables.tradeFair.program') }}</td>
                      <td>
                        {{ $t(`forms.sponsors.${item.attributes.sponsor}`) }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tradeFair.aumaId') }}</td>
                      <td>{{ item.attributes.auma_id }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('tradeFair.bafaId') }}</td>
                      <td>{{ item.attributes.bafa_id }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('tradeFair.aumaDatabase') }}</td>
                      <td>
                        <a
                          target="_blank"
                          :href="item.attributes.link_auma_database"
                          >{{
                            item.attributes.link_auma_database[$i18n.locale]
                          }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tradeFair.organizer') }}</td>
                      <td>{{ item.attributes.host_name }}</td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t('tradeFair.organizer') }}
                        {{ $t('forms.fields.website') }}
                      </td>
                      <td>
                        <a
                          target="_blank"
                          :href="item.attributes.host_website"
                          >{{ item.attributes.host_wbsite }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t('tradeFair.organizer') }}
                        {{ $t('forms.fields.email') }}
                      </td>
                      <td>
                        <a :href="`mailto:${item.attributes.host_email}}`">{{
                          item.attributes.host_email
                        }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t('tradeFair.organizer') }}
                        {{ $t('company.address') }}
                      </td>
                      <td>{{ item.attributes.host_address }}</td>
                    </tr>
                  </table>
                </v-col>

                <v-col>
                  <div v-if="item.relationships.participations">
                    <p>
                      <strong>{{
                        $t('tradeFair.registeredExhibitors')
                      }}</strong>
                      ({{ $t('company.confirmed') }})
                    </p>

                    <p class="row ma-0 p-2 my-4">
                      <v-chip
                        v-if="participantsCountBySubmitted(item)"
                        class="mr-2"
                        color="warning"
                        pill
                        small
                      >
                        {{ participantsCountBySubmitted(item) }}
                        {{ $t(`tables.participation.count.submitted`) }}
                      </v-chip>
                      <v-chip
                        v-if="participantsCountByApproved(item)"
                        class="mr-2"
                        color="success"
                        pill
                        small
                      >
                        {{ participantsCountByApproved(item) }}
                        {{ $t(`tables.participation.count.approved`) }}
                      </v-chip>
                      <v-chip
                        v-if="participantsCountByCancelled(item)"
                        class="mr-2"
                        color="gray"
                        pill
                        small
                      >
                        {{ participantsCountByCancelled(item) }}
                        {{ $t(`tables.participation.count.cancelled`) }}
                      </v-chip>
                      <v-chip
                        v-if="participantsCountByRejected(item)"
                        class="mr-2"
                        color="gray"
                        pill
                        small
                      >
                        {{ participantsCountByRejected(item) }}
                        {{ $t(`tables.participation.count.rejected`) }}
                      </v-chip>
                    </p>

                    <table class="default-table striped-table">
                      <tr
                        v-for="participation in item.relationships
                          .participations"
                        :key="participation.id"
                      >
                        <td v-if="participation.company">
                          <router-link
                            :to="{
                              name: 'admin.company',
                              params: { id: participation.company.company_id },
                            }"
                          >
                            {{ participation.company.company }}
                          </router-link>
                        </td>
                        <td>
                          <v-chip
                            :color="colorStatus(participation)"
                            pill
                            small
                          >
                            {{
                              $t(
                                `tables.participation.pageStatus.${participation.status}`
                              )
                            }}
                          </v-chip>
                        </td>
                        <td>
                          <v-btn
                            small
                            outlined
                            color="secondary"
                            @click="openContactPersonDialog(participation)"
                          >
                            {{ $t('participation.contactPerson') }}
                          </v-btn>
                        </td>
                      </tr>
                    </table>
                    <v-dialog
                      v-model="dialog"
                      max-width="800px"
                      @click:outside="closeContactPersonDialog"
                      :retain-focus="false"
                    >
                      <v-card>
                        <v-card-title class="justify-space-between">
                          <h4 class="primary--text">
                            {{ $t('participation.contactPerson') }}
                          </h4>
                          <v-btn
                            color="primary"
                            @click="closeContactPersonDialog()"
                            icon
                          >
                            <v-icon>fal fa-times</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-container>
                          <exhibitor-contacts
                            :participationContacts="
                              selectedParticipation.contacts
                            "
                            :participation="selectedParticipation"
                            v-if="selectedParticipation"
                          ></exhibitor-contacts>
                        </v-container>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="closeContactPersonDialog()"
                          >
                            {{ $t('common.close') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>

                  <p class="mt-5">
                    <strong>{{ $t('tradeFair.editTradeFair') }}</strong>
                  </p>
                  <div>
                    <v-btn
                      v-if="item.attributes.flag_registration_is_open"
                      :disabled="!isAvailableToReopen(item)"
                      outlined
                      @click="handleReopenConfigurationForm(item)"
                      >{{ $t('tradeFair.reopenRegistration.form') }}
                    </v-btn>
                    <p
                      v-if="
                        item.relationships.participations &&
                        !!item.relationships.participations.length &&
                        item.attributes.flag_registration_is_open
                      "
                    >
                      {{ $t('tradeFair.reopenRegistration.alert') }}
                    </p>
                  </div>
                  <div class="mt-5">
                    <v-menu
                      v-if="isAvailableToChangeDeadline(item)"
                      ref="menu"
                      v-model="item.menu"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn outlined class="me-5" v-on="on">{{
                          $t('tradeFair.changeDeadline')
                        }}</v-btn>
                        {{ $t('tradeFair.deadline') }}:
                        {{
                          item.attributes.registration_deadline | dateByLocale
                        }}
                      </template>
                      <v-date-picker
                        no-title
                        scrollable
                        v-model="item.deadline"
                        :allowed-dates="(val) => allowedDates(val, item)"
                      >
                        <v-spacer />
                        <v-btn text color="primary" @click="item.menu = false">
                          {{ $t('common.cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="handleChangeDeadline($event, item)"
                        >
                          {{ $t('common.save') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <p v-if="item.attributes.summary_submitted_at">
                    <strong>{{ $t('tradefairsummary.title') }}</strong>
                  </p>
                  <div
                    v-if="item.attributes.summary_submitted_at"
                    class="row ma-0 p-2 my-4"
                  >
                    <v-btn
                      :disabled="!item.attributes.summary_generated_file"
                      @click="
                        downloadTradeFairSummary({ tradeFairId: item.id })
                      "
                      text
                      color="secondary"
                      style="text-transform: none"
                    >
                      <v-icon size="16" class="mr-2"> fal fa-file-pdf</v-icon>
                      {{
                        $t(
                          'tradefairsummary.submitted.downloadTradefairSummary'
                        )
                      }}
                    </v-btn>
                    <v-btn
                      @click="
                        downloadTradeFairSummaryMedia({ tradeFairId: item.id })
                      "
                      text
                      color="secondary"
                      class="ml-5"
                      style="text-transform: none"
                    >
                      <v-icon size="16" class="mr-2"> fal fa-file-image</v-icon>
                      {{
                        $t('tradefairsummary.submitted.downloadTradefairImages')
                      }}
                    </v-btn>
                  </div>
                  <div class="mt-8">
                    <div v-if="item.attributes.hidden" class="row mx-0">
                      <v-btn outlined @click="handleUnhide(item)"
                        >{{ $t('tradeFair.hidden.unhide') }}
                      </v-btn>
                      <p class="ml-2 mt-2">
                        {{ $t('tradeFair.hidden.hiddenStatus') }}
                      </p>
                    </div>
                    <div v-if="!item.attributes.hidden" class="row mx-0">
                      <v-btn outlined @click="handleHide(item)"
                        >{{ $t('tradeFair.hidden.hide') }}
                      </v-btn>
                      <p class="ml-2 mt-2">
                        {{ $t('tradeFair.hidden.unhiddenStatus') }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { PARTICIPATION_STATUS } from '@/enums/participationStatus'
import FairSearch from '@/components/admin/Fair/FairSearch'
import RegistrationStatusChip from '@/components/base/RegistrationStatusChip.vue'
import ExhibitorContacts from '@/components/common/Exhibitor/Contacts'
import date from '@/mixins/date'

export default {
  name: 'AdminFairs',

  components: {
    FairSearch,
    ExhibitorContacts,
    RegistrationStatusChip,
  },

  mixins: [date],

  data() {
    return {
      dialog: false,
      selectedParticipation: null,
      fairs: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      filters: null,
    }
  },

  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand',
        },
        {
          text: '',
          value: 'flag_registration_is_open',
          sortable: false,
        },
        {
          text: this.$t('forms.fields.name'),
          value: 'name',
          sortable: true,
        },
        {
          text: 'Status',
          value: 'registration_type',
          sortable: false,
        },
        {
          text: this.$t('tradeFair.deadline'),
          value: 'registration_deadline',
          sortable: true,
        },
        {
          text: this.$t('tables.tradeFair.startAt'),
          value: 'start_date',
          sortable: true,
        },
        {
          text: `${this.$t('company.city')}, ${this.$t('company.country')}`,
          value: 'city',
          sortable: true,
        },
        {
          text: this.$t('tradeFair.filters.program'),
          value: 'sponsor',
          sortable: true,
        },
        {
          text: this.$t('tradeFair.executionCompany'),
          value: 'organizer',
          sortable: false,
        },
        {
          text: this.$t('tables.tradeFair.association'),
          value: 'association',
          sortable: false,
        },
        {
          text: this.$t('participation.confirmed'),
          sortable: true,
          value: 'participation_count',
        },
        {
          text: this.$t('interest.interestedPersons'),
          sortable: true,
          value: 'interested_companies_count',
        },
      ]
    },
  },

  methods: {
    ...mapActions('tradefair', ['reopenConfigurationForm', 'unhide', 'hide']),

    ...mapActions('organizer', [
      'updateTradeFair',
      'downloadTradeFairSummary',
      'downloadTradeFairSummaryMedia',
    ]),

    openContactPersonDialog(participation) {
      this.selectedParticipation = null
      this.selectedParticipation = participation
      this.dialog = true
    },

    closeContactPersonDialog() {
      this.dialog = false
      this.selectedParticipation = null
    },

    goToFairEvaluation(fairId, role) {
      this.$router.push({
        name: 'SingleEvaluation',
        params: { role: role },
        query: { id: fairId },
      })
    },

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i]
          ? '-' + this.options.sortBy[i]
          : this.options.sortBy[i]
      }

      let response = (
        await this.$axios.get('/api/trade-fairs', {
          params: {
            languages: '*',
            'filter[tradefair_id]': this.filters.names,
            'filter[organizer_id]': this.filters.organizers,
            'filter[years]': this.filters.years,
            'filter[sponsor]': this.filters.sponsors,
            'filter[future]': this.filters.future,
            include: [
              'country',
              'organizer',
              'associations',
              'interestedCompaniesCount',
              'participationsCount',
              'participations',
              'participations.contacts',
              'participations.contacts.language',
              'accessUsers',
              'surveySubmittedCount',
            ],
            sort: sort,
            'page[number]': this.options?.page ?? 1,
            'page[size]': this.options?.itemsPerPage ?? 10,
          },
        })
      ).data

      this.fairs = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    async handleHide(item) {
      await this.hide({ tradeFairId: item.id })

      await this.load()
    },

    async handleUnhide(item) {
      await this.unhide({ tradeFairId: item.id })

      await this.load()
    },

    async handleReopenConfigurationForm(item) {
      await this.reopenConfigurationForm({ tradeFairId: item.id })
      await this.load()
    },

    isAvailableToReopen(item) {
      return (
        item.relationships.participations &&
        item.attributes.flag_registration_is_open &&
        !item.relationships.participations.find((el) => {
          return el.approved_at
        })
      )
    },

    isAvailableToChangeDeadline(item) {
      return (
        item.attributes.flag_registration_is_open &&
        item.attributes.registration_deadline !== null &&
        this.$moment(item.attributes.registration_deadline).isBefore()
      )
    },

    async handleChangeDeadline(val, item) {
      await this.updateTradeFair({
        registrationDeadline: this.$options.filters.dateTime(item.deadline),
        id: item.id,
      })
      item.menu = false

      await this.load()
    },

    allowedDates(val, item) {
      return (
        val >= this.$moment().format('YYYY-MM-DD') &&
        val <= item.attributes.start_date.slice(0, 10)
      )
    },

    colorStatus(participation) {
      switch (participation.status) {
        case PARTICIPATION_STATUS.SUBMITTED:
          return 'warning'
        case PARTICIPATION_STATUS.APPROVED:
          return 'success'
        case PARTICIPATION_STATUS.CANCELLED:
          return 'gray'
        case PARTICIPATION_STATUS.REJECTED:
          return 'gray'
        case PARTICIPATION_STATUS.WAITING:
          return 'light-green'
        default:
          return 'gray'
      }
    },

    participantsCountBySubmitted(tradeFair) {
      return tradeFair.relationships.participations?.filter(
        (el) => el.status === PARTICIPATION_STATUS.SUBMITTED
      ).length
    },

    participantsCountByApproved(tradeFair) {
      return tradeFair.relationships.participations?.filter(
        (el) => el.status === PARTICIPATION_STATUS.APPROVED
      ).length
    },

    participantsCountByRejected(tradeFair) {
      return tradeFair.relationships.participations?.filter(
        (el) => el.status === PARTICIPATION_STATUS.REJECTED
      ).length
    },

    participantsCountByCancelled(tradeFair) {
      return tradeFair.relationships.participations?.filter(
        (el) => el.status === PARTICIPATION_STATUS.CANCELLED
      ).length
    },

    participantsCountByWaiting(tradeFair) {
      return tradeFair.relationships.participations?.filter(
        (el) => el.status === PARTICIPATION_STATUS.WAITING
      ).length
    },

    coExhibitorsCount(tradeFair) {
      return tradeFair.relationships.participations.reduce(
        (acc, el) => acc + el.coexhibitors.length,
        0
      )
    },
  },

  watch: {
    options() {
      if (!this.form.loading && !!this.filters) {
        this.load()
      }
    },

    filters: {
      handler() {
        this.options.page = 1

        this.load()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.striped-table {
  border-collapse: collapse;
  width: 100%;
}

.striped-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

.striped-table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.striped-table tr:nth-child(even) {
  background-color: #ffffff;
}
</style>
