export default {
  tradeFair: {
    tradeFair: 'Trade fair',
    tradeFairs: 'Trade fairs',
    overviewEventAndStatus: 'Event overview and registration status of the foreign trade fair programmes.',
    date: 'Date',
    location: 'Location',
    deadline: 'Registration deadline',
    changeDeadline: 'Change deadline date',
    reopenRegistration: {
      form: 'Reopen registration form for organizer',
      alert: 'This fair already has participations and can therefore not be released for editing again.',
    },
    hidden: {
      hide: 'Hide trade fair',
      unhide: 'Display trade fair',
      hiddenStatus: 'State: Invisible',
      unhiddenStatus: 'State: Visible',
    },
    editTradeFair: 'Edit trade fair',
    executionCompany: 'Implementation company',
    executionCompanyDescription: 'Implementation company within the meaning of the General Conditions of Participation (BTB)',
    projectManager: 'Project manager',
    confirmationPDF: 'Confirmation PDF',
    confirmedRegistrations: 'Confirmed registrations',
    confirmedExhibitorApplications: 'Confirmed exhibitor registrations',
    organizer: 'Organizer',
    inCooperationWith: 'inCooperationWith',
    dynAMPSandbox: 'DynAMP Sandbox',
    testDynAMPConfiguration: 'Test DynAMP configuration and form display.',
    filters: {
      futureEventsOnly: 'future events only',
      name: 'Trade Fair name',
      year: 'Year',
      program: 'Trade fair programme',
      association: 'Trade association',
      country:'Country',
    },
    registrationDocuments: 'Registration documents for German Pavilions',
    DFG: 'DFG',
    assignedUsers: 'Assigned users',
    dates: 'Fair dates',
    aumaId: 'AUMA ID',
    bafaId: 'Bafa ID',
    aumaDatabase: 'AUMA Database',
    registeredExhibitors: 'Registered exhibitors',
    gtq: 'GTQ',
    funding: 'Funding',
    registrationIsCancelled: 'Registration is cancelled',
    registrationIsNotOpened: 'This registration form is not yet available',
    registrationIsExternalLocked: 'Registration is external locked',
    tradeFairIsFinished: 'Trade fair is finished',
    registrationDeadlineIsPassed: 'Registration deadline has expired',
  },
}
