<template>
    <div>
        <div class="d-flex">
            <h3 class="primary--text">
                {{ $t('exhibitorSurvey.evaluation.singleEvaluation') }}
            </h3>
            <v-spacer></v-spacer>
            <v-btn :to="{ name: 'MultipleEvaluation' }" elevation="1" color="primary">{{
                $t('exhibitorSurvey.evaluation.overallEvaluation')
            }}</v-btn>
        </div>

        <FilterBar
            @loadParticipations="getFairParticipations"
            :showAllFilter="false"
            :showButtonLoading="loading"
            class="mb-8"
        ></FilterBar>

        <v-row v-if="$route.query.id">
            <v-col>
                <small>
                    {{ $t('exhibitorSurvey.evaluation.atTheseTradefairs') }}
                    {{ participations?.length }}<br />
                    {{ $t('exhibitorSurvey.evaluation.tookPartInSurvey') }}
                    {{ answeredCount }}
                </small>
            </v-col>
        </v-row>

        <v-data-table
            :headers="headers"
            :items="companiesDetails"
            item-value="id"
            class="elevation-1 rounded-0"
            :loading="loading"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:item="{ item, on }">
                <tr v-on="on" @click="viewSurveyDetails(item)" style="cursor: pointer">
                    <td>{{ item.name }}</td>
                    <td>{{ item.contact_name }}</td>
                    <td>{{ item.contact_email }}</td>
                    <td>
                        <v-chip v-if="item.submitted" color="success" small>
                            {{ $t('exhibitorSurvey.evaluation.surveySubmitted') + ':' }}
                            {{ formatDateByLocale(item.submitted) }}
                        </v-chip>
                        <v-chip v-else color="error" small>
                            {{ $t('exhibitorSurvey.evaluation.surveyNotSubmitted') }}
                        </v-chip>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="showDetailsDialog">
            <div>
                <survey
                    :participationId="selectedParticipationId"
                    :exhibitorName="
                        participations?.find((participation) => participation.id === selectedParticipationId)
                            ?.relationships?.company?.attributes?.company
                    "
                    :key="reRender"
                />

                <v-btn
                    color="primary"
                    style="position: absolute; bottom: 80px; right: 50px; z-index: 1"
                    @click="showDetailsDialog = false"
                    >{{ $t('exhibitorSurvey.evaluation.buttonDialogClose') }}</v-btn
                >
            </div>
        </v-dialog>
    </div>
</template>

<script>
import FilterBar from '@/components/common/ExhibitorSurvey/EvaluationFilter/FilterBar'
import Survey from '../components/common/ExhibitorSurvey/Survey.vue'
import date from '@/mixins/date'
import { mapGetters } from 'vuex'

export default {
    name: 'SingleEvaluation',

    components: {
        FilterBar,
        Survey,
    },

    mixins: [date],

    data() {
        return {
            exhibitorName: null,
            participations: null,
            showDetailsDialog: false,
            loading: false,
            selectedParticipationId: null,
            reRender: 0,
        }
    },

    computed: {
        ...mapGetters('auth', ['user', 'isExhibitor', 'isOrganizer', 'isProjectManager', 'isAdmin', 'isAssociation']),
        headers() {
            return [
                {
                    text: this.$t('exhibitorSurvey.evaluation.exhibitor'),
                    value: 'name',
                },
                {
                    text: this.$t('exhibitorSurvey.evaluation.contactPerson'),
                    value: 'contact_name',
                    sortable: false,
                },
                {
                    text: this.$t('exhibitorSurvey.evaluation.contactPersonEmail'),
                    value: 'contact_email',
                    sortable: false,
                },
                {
                    text: this.$t('exhibitorSurvey.evaluation.submissionStatus'),
                    value: 'submitted',
                },
            ]
        },

        answeredCount() {
            return this.companiesDetails?.filter((participation) => participation.submitted).length
        },

        companiesDetails() {
            return this.participations?.map((participation) => {
                const contact = this.getExhibitorContact(participation.relationships?.contacts)
                return {
                    id: participation.id,
                    name: participation.relationships.company.attributes.company,
                    contact_name: contact?.attributes?.first_name + ' ' + contact?.attributes?.last_name,
                    contact_email: contact?.attributes?.email,
                    submitted: participation.attributes.survey_submitted_at,
                }
            })
        },
    },

    methods: {
        getExhibitorContact(contacts) {
            return (
                contacts.find((c) => c?.attributes?.contact_type?.includes('on_site')) ??
                contacts.find((c) => c?.attributes?.contact_type?.includes('participation_preparation')) ??
                null
            )
        },

        viewSurveyDetails(item) {
            if (!item.submitted) {
                return
            }
            this.selectedParticipationId = item.id
            this.reRender++
            this.showDetailsDialog = true
        },

        async getFairParticipations() {
            if (!this.$route.query.id) {
                this.participations = null
                return
            }
            this.loading = true
            try {
                const response = await this.$axios.get(`/api/trade-fairs/${this.$route.query.id}/participants`, {
                    params: {
                        languages: '*',
                        include: ['company', 'contacts'],
                    },
                })
                this.participations = response.data.data
                this.loading = false
            } catch (error) {
                this.loading = false
                console.log('error fetching fair participations')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
:deep(.v-data-table-header__icon) {
    margin-left: 5px;
}
</style>
