<template>
  <div class="d-flex">
    <v-textarea
      @input="handleTextChange(children.id, $event)"
      :value="textArea[children.attributes.identifier]?.content"
      outlined
      :placeholder="$t('exhibitorSurvey.placerHolders.textArea')"
      :disabled="disabled"
    ></v-textarea>
  </div>
</template>

<script>
import { debounce } from 'lodash'
export default {
  props: {
    value: Object,
    children: Object,
    disabled: Boolean,
  },

  data() {
    return {
      textArea: this.value,
      debouncedHandler: null,
    }
  },

  methods: {
    handleTextChange(id, newContent) {
      if (!this.debouncedHandler) {
        this.debouncedHandler = debounce((id, newContent) => {
          if (newContent) {
            this.$emit('update:textAnswer', { id, content: newContent })
            this.$emit('startFetching', false)
          } else {
            this.$emit('delete:textAnswer', id)
            this.$emit('startFetching', false)
          }
        }, 850)
      }
      this.debouncedHandler(id, newContent)
    },
  },
}
</script>

<style scoped></style>
