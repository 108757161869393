export default {
  exhibitorSurvey: {
    title: 'Ausstellerbefragung',
    subtitle:
      'Vielen Dank für Ihre Teilnahme am Auslandsmesseprogramm des BMWK/BMEL.',
    intro:
      'Ihr Feedback hilft uns dabei, das Auslandsmesseprogramm zu evaluieren. Die Beantwortung der nachfolgenden Fragen dauert ca. 5 Minuten. Alle Informationen werden vertraulich behandelt.<br><br><b>Vielen Dank für Ihre Mithilfe.</b>',
    tradeFairName: 'Messetitel',
    tradeFairDate: 'Messetermin',
    exhibitorName: 'Name des ausstellenden Unternehmens',
    comments: 'Anmerkung/Kommentar',
    email: 'E-Mail-Adresse',
    emailHint:
      'Bitte geben Sie Ihre E-Mail Adresse an, wenn Sie möchten, dass wir Sie wegen Ihres Feedbacks kontaktieren.',
    submitSurvey: 'Umfrage absenden',
    startSurvey: 'Umfrage starten',
    showSurvey: 'Umfrage ansehen',
    successMsg:
      'Vielen Dank! Sie haben diese Umfrage am {submissionDate} eingereicht.',
    successMsgWhenSubmitted:
      'Vielen Dank! Sie haben diese Umfrage erfolgreich eingereicht.',
    continueButton: 'Weiter',
    placerHolders: {
      textField: 'Ihre Eingabe',
      textArea: 'Ihre Texteingabe',
    },
    errors: {
      linkInvalid: 'Der Link ist ungültig.',
    },
    evaluation: {
      tradeFairDetailedView: 'Einzelansicht Messe',
      overallEvaluation: 'Gesamtauswertung',
      selectPeriod: 'Zeitraum auswählen',
      selectPeriodFrom: 'Zeitraum von',
      selectPeriodTo: 'Zeitraum bis',
      buttonDialogClose: 'Schliessen',
      buttonSearch: 'Auswerten',
      from: 'Von',
      to: 'Bis',
      errorAtleastOneFilter:
        'Bitte wählen Sie mindestens ein Filterkriterium aus.',
      errorNoResults:
        'Es wurden keine Ergebnisse für die ausgewählten Filterkriterien gefunden.',
      errorSelectFair:'Bitte wählen Sie eine Messe aus',
      numberSelectedTradeFairs: 'Anzahl der gewählten Messen',
      numberQuestionedAnswers: 'Gefragt/Beantwortet',
      individualResult: 'Ergebnisse einzelner Messen',
      textInput: 'Texteingabe',
      textInputs: 'Texteingaben',
      exhibitor: 'Aussteller',
      singleEvaluation: 'Einzelauswertung',
      contactPerson: 'Kontaktperson',
      contactPersonEmail: 'E-Mail Kontaktperson',
      submissionStatus: 'Abgabe-Status',
      surveySubmitted: 'Umfrage abgegeben',
      surveyNotSubmitted: 'Umfrage nicht abgegeben',
      surveyStartedAt: 'Startdatum',
      surveyStoppedAt: 'Enddatum',
      surveyRunning: 'Befragung läuft',
      submittedCount: 'Anzahl der Abgaben',
      atTheseTradefairs: 'Aussteller insgesamt:',
      tookPartInSurvey: 'Davon haben an der Umfrage teilgenommen:',
    },
  },
}
